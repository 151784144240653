import React, { useEffect, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import Color from 'color';

import { Heading, Button, Link, Text, Flex, Box } from 'rebass';
import { Label, Input } from '@rebass/forms';
import AccountLayout from './account_layout.jsx';
import Loading from './components/loading.jsx';
import FlashAlert from './components/flash_alert.jsx';
import styled from '@emotion/styled';

import { retrieveOrders } from './models/order.js';
import { retrieveSubscriptions } from './models/subscriptions.js';
import { Settings } from './settings.js'

import { dataProductSearchPath } from './routes.js'

const OrderRow = props => {
  const order = props.order
  let downloadLink = <Text>{props.order.order_status}</Text>;
  console.log(order);
  if (order.download_url) {
    downloadLink = <Link href={order.download_url}>Download</Link>
  } else if (order.payment_url) {
    downloadLink = <Link as={RouterLink} to={order.payment_url}>{props.order.order_status}</Link>
  }

  let createdAt = order.created_at;

  return <tr>
    <td>
      <Text color='gray' mb={1}>{order.product_name}</Text>
      <Text>{order.search_name}</Text>
    </td>
    <td><Text>{order.package_names.join(", ")}</Text></td>
    <td>{Intl.NumberFormat().format(order.records)}</td>
    <td>{downloadLink}
      <Box mt={2}><Link as={RouterLink} to={dataProductSearchPath(order.data_product_id, order.filters)}>Buy again</Link></Box>
    </td>
    <td>{createdAt}</td>
    {Settings.martingale && <td>{order.charge_code}</td>}
  </tr>
}

const StyledTable = styled.table`
  {
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.1);
  border-radius: 4px;
  background-color: white;
  border-collapse:collapse;
  border-spacing:0;
  tbody tr td {
  border: 1px solid rgba(34, 36, 38, 0.1);
  padding: 30px 20px;
  }
  thead tr {
  background-color: ${props => Color(props.theme.colors.secondary).alpha(0.05).string()};
  }
  thead tr th {
  text-align: left;
  padding: 10px 20px;
  color: ${props => props.theme.colors.secondary};
  font-weight: bold;
  border: 1px solid rgba(34, 36, 38, 0.1);
  }
  }
`;

const OrdersList = props => {
  const orders = props.orders;
  const body = orders.map(o => <OrderRow key={o.id} order={o} />);
  return <Box>
    <Heading fontSize={3} mb={2}>Orders</Heading>
    <Text mb={3}>Download your data below. You can also <Link as={RouterLink} to="/api">access your data via API.</Link></Text>
    <StyledTable>
      <thead>
        <tr>
          <th>Name</th>
          <th>Products</th>
          <th>Records</th>
          <th>Link</th>
          <th>Date</th>
          {Settings.martingale && <th>Charge Code</th>}
        </tr>
      </thead>

      <tbody>
        {body}
      </tbody>
    </StyledTable>
  </Box>
}

const DownloadRow = props => {
  const download = props.download
  let downloadLink = download.status_string;
  if (download.presigned_url) {
    downloadLink = <Link href={download.presigned_url}>Download</Link>
  }

  let createdAt = download.created_at;

  return <tr key={download.id}>
    <td>{createdAt}</td>
    <td>{download.total_records ? Intl.NumberFormat().format(download.total_records) : '—'}</td>
    <td>{downloadLink}</td>
  </tr>
}


const SubscriptionDownloads = props => {
  const subscription = props.subscription;
  const downloads = subscription.downloads

  let downloadBlock;
  if (downloads === null || downloads.length == 0) {
    downloadBlock = <Text>No downloads are available for this subscription yet.</Text>;
  } else {
    const body = downloads.map(d => <DownloadRow key={d.id} download={d} />);
    downloadBlock = <StyledTable>
      <thead>
        <tr>
          <th>Date</th>
          <th>Records</th>
          <th>Link</th>
        </tr>
      </thead>

      <tbody>
        {body}
      </tbody>
    </StyledTable>
  }

  return (
    <Box mb={4}>
      <Heading fontSize={2} mb={2}>{subscription.data_product_name}</Heading>
      <Text mb={2}>{subscription.data_product_packages.map(p => p.name).join(", ")}{subscription.charge_code && ' – ' + subscription.charge_code}</Text>
      {downloadBlock}
    </Box>
  );
}


const EmptyOrdersPanel = (props) => {
  if (Settings.martingale) {
    return <Flex
      flexDirection='column'
      alignItems='center'
      mx='auto'
      fontSize={3}
      sx={{ maxWidth: '600px', position: 'relative' }}
      p={4}
      mb={3}
      variant='primaryBorder'>
      <Heading sx={{ textTransform: 'uppercase' }}
        mb={3}
        color='primaryInspiredTextColor'>Link your charge codes</Heading>
      <Text>
        The data shop is integrated with your charge codes. Attach them to your profile to unlock any data already purchased by others under the same code.
      </Text>

      <Button mt={3} sx={{ width: '200px' }}
        as={RouterLink} to='/account/charge_codes'>Manage charge codes</Button>

    </Flex>
  } else {
    return <Text>You haven't bought any data yet.</Text>;
  }
}

export default function DownloadsScreen(props) {
  const [orders, setOrders] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    retrieveOrders({ vendorId: props.dataProductVendorId })
      .then(res => {
        setOrders(res.data.orders);
      })
      .catch(error => {
        setError(error.response.data['error']);
      })

  }, [])

  useEffect(() => {
    retrieveSubscriptions({ vendorId: props.dataProductVendorId })
      .then(res => {
        setSubscriptions(res.data.subscriptions);
      })
      .catch(error => {
        setSubscriptions([]);
        setError(error.response.data['error']);
      })

  }, [])


  let subscriptionPanels;
  let ordersList;
  let topBannerWhenOrders;

  if (Settings.martingale) {
    topBannerWhenOrders =
      <Flex
        flexDirection='column'
        alignItems='center'
        mx='0'
        fontSize={3}
        variant='primaryBorder'
        sx={{ position: 'relative' }}
        p={4}
        mb={4}>
        <Text>Missing a dataset? <Link as={RouterLink} to='/account/charge_codes'>Add your charge code</Link></Text>
      </Flex>
  }

  if (orders && subscriptions) {
    if (subscriptions.length > 0) {
      subscriptionPanels =
        <Box mb={4}>
          <Heading fontSize={3} mb={2}>Subscriptions</Heading>
          <Text mb={3}>Your dataset subscriptions are below. New files will be generated each day and can be downloaded here.
            You can also <Link as={RouterLink} to="/api">access your data via API.</Link>
          </Text>
          {subscriptions.map(s => <SubscriptionDownloads key={s.id} subscription={s} />)}
        </Box>
    }

    if (orders.length > 0) {
      ordersList = <OrdersList orders={orders} />;
    }

    if (subscriptions.length == 0 && orders.length == 0) {
      ordersList = <EmptyOrdersPanel />
      topBannerWhenOrders = null;
    }
  } else {
    ordersList = <Flex justifyContent='center'><Loading /></Flex>;
  }

  return <AccountLayout {...props} activeTab='downloads'>
    <FlashAlert my={5} mx='auto' width='50%' location={props.location} />
    {topBannerWhenOrders}
    {subscriptionPanels}
    {ordersList}
  </AccountLayout>
}
