import React, { useState } from 'react';

import { Button, Flex, Box, Link } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import { curFmt } from '../util.js'

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  ElementsConsumer,
  Elements
} from '@stripe/react-stripe-js';


export const ErrorResult = ({children}) => (
  <Box my={3} color="red">{children}</Box>
);

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const CardForm = (props) => {
  const [name, setName] = useState('');
  const [postal, setPostal] = useState('');

  const handleSubmit = async (event) => {
    props.setFormDisabled(true);
    event.preventDefault();
    const {stripe, elements} = props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    props.onFormSubmitted(stripe, cardElement, name, postal);
  };


  const {stripe} = props;

  const formDisabled = props.formDisabled;
  const errorMessage = props.errorMessage;

  return (
    <form onSubmit={handleSubmit} className='stripe-form'>
      <label htmlFor="name">Full Name</label>
      <input
        id="name"
        required
        disabled={formDisabled}
        placeholder="John Smith"
        value={name}
        onChange={(event) => {
          setName(event.target.value)
        }}
      />
      <label htmlFor="cardNumber">Card Number</label>
      <CardNumberElement
        disabled={formDisabled}
        id="cardNumber"
        options={ELEMENT_OPTIONS}
      />
      <label htmlFor="expiry">Card Expiration</label>
      <CardExpiryElement
        disabled={formDisabled}
        id="expiry"
        options={ELEMENT_OPTIONS}
      />
      <label htmlFor="cvc">CVC</label>
      <CardCvcElement
        disabled={formDisabled}
        id="cvc"
        options={ELEMENT_OPTIONS}
      />
      <label htmlFor="postal">Postal Code</label>
      <input
        id="postal"
        disabled={formDisabled}
        required
        placeholder="12345"
        value={postal}
        onChange={(event) => { setPostal(event.target.value) }}
      />
      {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
      <Flex>
        <Button type="button"
                onClick={props.cancel}
                disabled={formDisabled}
                variant='outline'>
          Cancel
        </Button>

        <Box mx='auto' />

        <Button type="submit"
                disabled={formDisabled}
                variant='primary'>
          {props.ctaText}
        </Button>
      </Flex>
    </form>
  );
}

const InjectedCardForm = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CardForm {...props} stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);


export const StripeCardForm = (props) => {
  return (
    <Elements stripe={props.stripePromise}>
      <InjectedCardForm clientSecret={props.clientSecret}
                        setFormDisabled={props.setFormDisabled}
                        errorMessage={props.errorMessage}
                        formDisabled={props.formDisabled}
                        ctaText={props.ctaText || `Pay ${curFmt.format(props.total)}`}
                        total={props.total}
                        cancel={props.cancel}
                        onFormSubmitted={props.onFormSubmitted} />
    </Elements>
  )
};
