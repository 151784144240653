import React, { useState, useEffect } from 'react';

import { Redirect, useHistory, useLocation, Link as RouterLink } from 'react-router-dom'

import styled from '@emotion/styled';
import { Button, Link, Flex, Box, Text, Heading } from 'rebass';
import { Label, Input } from '@rebass/forms';

import * as _ from 'lodash';
import axios from './axios_config.jsx';
import NoSidebarHeader from './no_sidebar_header.jsx';
import AlertBox from './components/alert_box.jsx'

import { markOrderPaid, createOrderPaymentIntent } from './models/order.js';
import Loading from './components/loading.jsx'

import { StripeCheckoutForm } from './components/stripe_checkout_form.jsx'

import {loadStripe} from '@stripe/stripe-js';
import { Settings } from './settings.js';

let stripePk;
let stripePromise;
if (!Settings.martingale) {
  stripePk = document.querySelector('meta[name="stripe_publishable_key"]').content;
  stripePromise = loadStripe(stripePk);
}

const OrderPaymentForm = props => {
  let [formSubmitting, setFormSubmitting] = useState(false);
  let [order, setOrder] = useState(null);
  let [clientSecret, setClientSecret] = useState(null);

  let history = useHistory();
  let location = useLocation();

  const cancel = () => {
  }

  const onSuccessfulPayment = (result) => {
    markOrderPaid(order.id)
      .then(res => {
        history.push({ pathname: '/account/downloads',
                       state: { flash: 'Your payment was successful. Download your data below..' }});
      })
      .catch(error => {
        console.log("could not mark paid");
      });
  }

  useEffect(() => {
    createOrderPaymentIntent(props.orderId)
      .then(res => {
        setOrder(res.data);
        setClientSecret(res.data.client_secret)
      })
      .catch(error => {
        if (error.response && error.response.data) {
          history.push({ pathname: '/',
                         state: { flash: error.response.data.error }});
        } else {
          console.log(error);
        }
      })
  }, [])

  let paymentBox = <Flex justifyContent='center'><Loading/></Flex>;

  if (order !== null && clientSecret !== null) {
    console.log(order)
    paymentBox = <StripeCheckoutForm
                   stripePromise={stripePromise}
                   clientSecret={clientSecret}
                   total={order.total}
                   cancel={cancel}
                   onSuccessfulPayment={onSuccessfulPayment} />
  }

  return <Box mt={6}
                mx='auto'
                sx={{maxWidth: '500px'}}>

             <Flex
               flexDirection='column'
               py={4}
               px={5}
               sx={{borderRadius: "4px",
                    boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)'}}
               bg='white'>

               <AlertBox>Enter your payment information to complete your order.</AlertBox>
               {paymentBox}
             </Flex>
           </Box>
}

export default function OrderPaymentScreen(props) {
  const pageUrl = location.href;
  const matches = pageUrl.match(/orders\/(.*)\/payment/);
  const orderId = matches[1];
  return <Box sx={{minHeight: '100vh'}}>
           <NoSidebarHeader {...props} />
           <OrderPaymentForm orderId={orderId}
                               {...props} />
         </Box>
}
