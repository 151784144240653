import React, { useEffect, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Button, Link, Text, Flex, Box, Heading } from 'rebass';
import { Label, Input } from '@rebass/forms';
import AccountLayout from './account_layout.jsx';
import Loading from './components/loading.jsx';
import FlashAlert from './components/flash_alert.jsx';

import { cancelSubscription, retrieveSubscriptions } from './models/subscriptions.js';

import strftime from 'strftime';

const SubscriptionBox = props => {
  const [canceling, setCanceling] = useState(false);
  const subscription = props.subscription

  const cancel = (e) => {
    setCanceling(true)
    cancelSubscription(props.subscription)
      .then (res => {
        props.loadSubscriptions();
        setCanceling(false)
      })
      .catch(error => {
        alert("Something went wrong canceling your subscription.")
        setCanceling(false)
        console.log(error);
      })
  }

  let statusLine;
  let showCancel = true;
  if (subscription.currently_active) {
    if (subscription.canceled_at) {
      const date = new Date(subscription.paid_until);
      statusLine = `This subscription has been canceled and is paid through ${strftime('%B %d, %Y', date)}`
      showCancel = false;
    } else {
      statusLine = "This subscription is active"
    }
  } else {
    showCancel = false;
    statusLine = "This subscription is inactive"
  }

  return <Box key={subscription.id}>
           <h3>{subscription.data_product_name}</h3>
           <ul>{subscription.data_product_packages.map(p => <li key={p.id}>{p.name}</li>)}</ul>
           <Box mb={2}>
             <Text>{statusLine}</Text>
           </Box>
           {showCancel && <Button disabled={canceling} as={Link} onClick={cancel} variant='secondaryOutline'>Cancel subscription{canceling ? '...' : '' }</Button>}
         </Box>
}

const SubscriptionList = props => {
  const subscriptions = props.subscriptions;
  if (props.loading || subscriptions === null) {
    return <Box />;
  } else if (subscriptions.length === 0) {
    return <Box />;
  } else {
    const subs = subscriptions.map(s => <SubscriptionBox loadSubscriptions={props.loadSubscriptions} key={s.id} subscription={s} />);
    return <Box>
             <Heading fontSize={3} mb={2}>Subscriptions</Heading>
             {subs}
           </Box>
  }
}

export default function SubscriptionsPanel(props) {
  const [subscriptions, setSubscriptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const loadSubscriptions = () => {
    setLoading(true);
    retrieveSubscriptions({vendorId: props.vendorId})
      .then(res => {
        setLoading(false);
        setSubscriptions(res.data.subscriptions);
      })
      .catch(error => {
        console.log(error)
        setError(error.response.data['error']);
      })
  }

  useEffect(() => {
    loadSubscriptions();
  }, [])

  return <SubscriptionList loading={loading} loadSubscriptions={loadSubscriptions} subscriptions={subscriptions}/>
}
