const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
})

export const curFmt = { format: (px) => {
  if (px < 0.01 && px !== 0) {
    return(`$${px}`);
  } else {
    return(formatter.format(px));
  }
}}
