import React from 'react';

import { Link as RouterLink } from 'react-router-dom'

import { Button, Flex, Box, Heading } from 'rebass';

import NoSidebarLayout from './no_sidebar_layout.jsx';
import { TabNav, TabLink } from './components/tab_nav.jsx';
import { Settings } from './settings.js'

export default function AccountLayout(props) {
  return <NoSidebarLayout {...props}>
    <Flex mb={4}>
      <Heading fontSize={5}>Account Summary</Heading>
      <Box margin='auto' />
      <Button sx={{lineHeight: '25px'}} variant='outline' as={RouterLink} to="/">Get more data</Button>
    </Flex>

    <TabNav mb={3}>
      <TabLink as={RouterLink} to="/account/downloads" activated={props.activeTab == 'downloads' ? 1 : 0}>Downloads</TabLink>
      <TabLink as={RouterLink} to="/account/profile" activated={props.activeTab == 'profile' ? 1 : 0}>Profile</TabLink>

  { Settings.martingale &&
    <TabLink as={RouterLink} to="/account/charge_codes" activated={props.activeTab == 'charge_codes' ? 1 : 0}>Charge Codes</TabLink> }
    </TabNav>

    {props.children}
  </NoSidebarLayout>
  }
