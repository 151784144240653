import React, { useState, useEffect, useRef } from 'react'
import { Calendar as CalendarIcon } from '@styled-icons/ionicons-outline';
import ReactDOM from 'react-dom'
import { useTheme } from 'emotion-theming'

import { Flex, Box, Button, Text } from 'rebass'
import { Label } from '@rebass/forms'

import { DateRange } from 'react-date-range';
import strftime from 'strftime';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRangeFilter = props => {
  const node = useRef();

  const [showingPicker, setShowingPicker] = useState(false);

  const theme = useTheme();

  const dateToString = (date) => {
    return strftime("%Y-%m-%d", date);
  }

  const stringToDate = (str) => {
    if (str == "") {
      return null;
    } else {
      const [y, m, d] = str.split('-');
      return new Date(y, m - 1, d);
    }
  }

  let currentValue = props.currentValue;

  const emptyRangeState = {startDate: null, endDate: null, key: 'selection'};

  const [selectedRange, setSelectedRange] = useState(emptyRangeState);

  const handleOutsideClick = e => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    setShowingPicker(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    let currentValue = props.currentValue;
    if (currentValue === undefined || currentValue == null || currentValue == "" ||
        _.isEmpty(currentValue)) {
      setSelectedRange(emptyRangeState);
    } else {
      if (Array.isArray(currentValue)) {
        currentValue = currentValue[0];
      }
      const [startString, endString] = currentValue.split("_")
      setSelectedRange({
        startDate: stringToDate(startString),
        endDate: stringToDate(endString),
        key: 'selection'
      })
    }
  }, [props.currentValue]);

  const handleDateRangeChange = (item) => {
    const selection = item.selection;
    setSelectedRange(item.selection)
  }

  let fieldName = props.filter.field_display_name;

  const submit = () => {
    props.setFilterValue(dateToString(selectedRange.startDate) + "_" +
                         dateToString(selectedRange.endDate))
    setShowingPicker(false);
  }

  let prompt = 'Choose a date range';

  if (selectedRange.startDate && selectedRange.endDate) {
    const fmtString = "%b %-d, %Y";
    prompt = strftime(fmtString, selectedRange.startDate) + " to " +
      strftime(fmtString, selectedRange.endDate)
  }

  const clearInput = () => {
    setShowingPicker(false);
    props.setFilterValue(null);
    setSelectedRange({startDate: null, endDate: null, key: 'selection'});
  }

  const valueIsSelected = () => {
    return (selectedRange.startDate !== null && selectedRange.endDate !== null);
  }

  return <Box sx={{position: 'relative'}} mb={4} {...props}>
           <Flex
             sx={{minHeight: '27px'}}
             mb={2}>
             <Box><Label sx={{textTransform: 'capitalize', height: '100%'}} m={0}>{fieldName}</Label></Box>
             <Box mx='auto' />
             <Box>
               {valueIsSelected() && <Button onClick={clearInput} variant='labelAction'>Clear</Button>}
             </Box>
           </Flex>

           <Flex color='secondaryLightTextMuted'
                 onClick={() => setShowingPicker(!showingPicker)}
                 p={'8px'}
                 bg='secondaryPlaceholder'
                 sx={{borderRadius: '4px',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      userSelect: 'none',
                      borderColor: (showingPicker ? 'accent' : 'secondaryPlaceholder')
                     }}>
             <Flex justifyContent='space-between'
                   flexGrow={1} px={'8px'} sx={{minHeight: '36px'}} alignItems='center'>
               <Text>{prompt}</Text>
               <CalendarIcon color={'rgb(204, 204, 204)'} size={18} />
             </Flex>
           </Flex>

           {showingPicker && <Flex
                               flexDirection="column"
                               alignItems="middle"
                               p={2}
                               ref={node}
                               sx={{ position: 'absolute',
                                     marginTop: '2px',
                                     borderWidth: '1px',
                                     borderStyle: 'solid',
	                                   boxShadow: '0 1px 5px rgba(0, 0, 0, 0.46)',
                                     borderRadius: '4px',
                                     zIndex: 2 }}
                               bg='#FFFFFF'
                               color='primary'>
                               <Box>
                                  <DateRange
                                    editableDateInputs={false}
                                    onChange={item => handleDateRangeChange(item)}
                                    moveRangeOnFirstSelection={false}
                                    rangeColors={[theme.colors.gray]}
                                    ranges={[selectedRange]}
                                  />
                               </Box>
                               <Flex flexGrow={1}>
                                 <Button flexGrow={1}
                                         mr={2}
                                         variant='secondaryOutline'
                                         onClick={() => setShowingPicker(false)}>Cancel</Button>
                                 <Button flexGrow={1}
                                         variant='primary'
                                         onClick={submit}>Apply</Button>
                               </Flex>
                             </Flex>}
         </Box>
};

export default DateRangeFilter;
