import React, { useState, useEffect } from 'react';
import { Heading, Button, Box, Flex, Link, Text } from 'rebass';
import { Label, Input } from '@rebass/forms';
import AccountLayout from './account_layout.jsx'
import { getUser, linkChargeCode, removeChargeCode } from './models/user.js';

import { Settings } from './settings.js'
import _ from 'lodash';

const NewChargeCodeForm = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);
  const [chargeCode, setChargeCode] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFormDisabled(true);

    linkChargeCode(chargeCode).then((result) => {
      console.log(result)
      setChargeCode("");
      props.setUserProfile(result.data);
      setFormDisabled(false);
    }).catch(err => {
      setErrorMessage(err.response.data.error.message);
      setFormDisabled(false);
    });
  }

  return (
    <Box mb={4}>
      <Heading fontSize={3} mb={2}>Attach a New Charge Code</Heading>
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <Text color='red'>{errorMessage}</Text>
          <Input disabled={formDisabled} placeholder="CODE" value={chargeCode} onChange={e => setChargeCode(e.target.value)} name='name' type='text' />
        </Box>

        <Flex>
          <Button type="submit" variant="buttons.primary" onClick={handleSubmit} mr={2} sx={{width: '175px'}}>Submit</Button>
        </Flex>
      </form>
    </Box>
  )
}

const LinkedChargeCode = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    removeChargeCode(props.chargeCode).then((result) => {
      props.setUserProfile(result.data);
    }).catch(err => {
      setErrorMessage(err.response.data.error.message);
    });
  }

  return (
    <Flex mb={3} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
      <Text>{props.chargeCode} </Text>
      <Button onClick={handleSubmit} variant='blackOutline'>Remove</Button>
      { errorMessage && <Text mb="2" color='red'>{errorMessage}</Text> }
    </Flex>
  )
}

const ChargeCodeManagementPanel = (props) => {
  let chargeCodePanels = [];

  _.each(props.userProfile.buyers, (b) => {
    if (b.charge_code) {
      chargeCodePanels.push(
        <LinkedChargeCode key={b.id} setUserProfile={props.setUserProfile} chargeCode={b.charge_code}/>
      )
    }
  });

  return (
    <Box mb={4}>
      <NewChargeCodeForm setUserProfile={props.setUserProfile} />
      { !_.isEmpty(chargeCodePanels) && <Heading fontSize={3} mb={2}>Charge Codes</Heading> }
      {chargeCodePanels}
    </Box>
  )
}

export default function ChargeCodeScreen(props) {
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    getUser()
      .then(res => {
        setUserProfile(res.data);
      })
      .catch(error => {
        setError(error.response.data['error']);
      })
  }, [])

  return <AccountLayout {...props} activeTab='charge_codes'>
    <Box justifyContent='space-between'>
    <Flex
          flexDirection='column'
          alignItems='center'
          mx='auto'
          fontSize={3}
          sx={{maxWidth: '600px', position: 'relative'}}
          p={4}
          mb={3}
          variant='primaryBorder'>
        <Text>Attach charge codes to your data shop account to gain access to datasets purchased by other team members.</Text>
    </Flex>

    <Box sx={{maxWidth: '600px'}} mt={4} mx='auto' >
      { userProfile && <ChargeCodeManagementPanel userProfile={userProfile} setUserProfile={setUserProfile} /> }
    </Box>
    </Box>
  </AccountLayout>
}
