import React, { useState } from 'react';

import { Redirect, useHistory, useLocation, Link as RouterLink } from 'react-router-dom'

import styled from '@emotion/styled';
import { Button, Link, Flex, Box, Text, Heading } from 'rebass';
import { Label, Input } from '@rebass/forms';

import * as _ from 'lodash';
import NoSidebarHeader from './no_sidebar_header.jsx';
import axios from './axios_config.jsx';
import FlashAlert from './components/flash_alert.jsx'

const LogInForm = props => {
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };

  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);

  const logIn = (e) => {
    e.preventDefault();
    setFormDisabled(true);
    axios.post('/users/sign_in', { user: { email: email, password: password } })
      .then(res=>{
        let role_string = 'shopper';
        let cartEmpty = _.isEmpty(props.cart);

        if (res.data.user.vendor_id === props.dataProductVendorId) {
          role_string = 'shop_admin';
        }
        props.setUserSession({
          userId: res.data.user.id,
          role: role_string
        })

        if (location.state.nextPage) {
          history.replace({pathname: location.state.nextPage})
        } else {
          history.replace({pathname: '/', state: { flash: "You are now logged in." }});
        }
      })
      .catch(error => {
        console.log(error);
        setFormDisabled(false);
        setErrorMessage(error.response.data['error']);
      });
  }

  let errorBox;
  if (errorMessage !== '') {
    errorBox = <Box py={3} mx='auto' color='red' >
                 <Text>{errorMessage}</Text>
               </Box>
  }

  if (props.userSession) {
    return(<Redirect to={{ pathname: "/", state: { flash: "You are signed in." }}} />);
  } else {
    return(
      <Flex
        flexDirection='column'
        mt={3}
        mx='auto'
        sx={{width: ['100%', '80%', '60%'],
             maxWidth: '600px'}}>
        <Flex
          flexDirection='column'
          py={4}
          px={5}
          sx={{borderRadius: "4px",
               boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)'}}
          bg='white'>
          <FlashAlert />
          <Heading my={4}>Sign in to continue</Heading>
          <form onSubmit={logIn}>
            <Box mb={3}>
              <Label>Email</Label>
              <Input disabled={formDisabled} onChange={e => setEmail(e.target.value)} name="email" type='text' />
            </Box>
            <Box mb={4}>
              <Label>Password</Label>
              <Input disabled={formDisabled} onChange={e => setPassword(e.target.value)} name='password' type='password' />
            </Box>
            <Input type='submit' variant='buttons.primary' value='Log in' disabled={formDisabled} my={3} py={3} sx={{width: '175px' }} mx="auto" />
            {errorBox}
          </form>
        </Flex>
        <Flex mt={4} mx={3}>
          <Link as={RouterLink} to='/users/passwords/new'>Forgot password?</Link>
          <Box mx='auto' />
          <Link as={RouterLink} to={{pathname: '/users/registrations/new', state: { nextPage: location.state ? location.state.nextPage : '/' }}}>Create account</Link>
        </Flex>
      </Flex>);
  }
}

export default function LogInScreen(props) {
  return <Box sx={{minHeight: '100vh'}}>
           <NoSidebarHeader {...props} />
           <LogInForm {...props} />
         </Box>
}
