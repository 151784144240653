import React, { useState, useEffect } from 'react';

import { useHistory, Link as RouterLink } from 'react-router-dom'

import NoSidebarLayout from './no_sidebar_layout.jsx'
import { createCartPaymentIntent, markCartPaid } from './models/cart.jsx'

import { Li, Button, Text, Flex, Box, Link, Heading } from 'rebass'

import { Label, Input, Checkbox } from '@rebass/forms'

import FlashAlert from './components/flash_alert.jsx'
import AlertBox from './components/alert_box.jsx'
import Loading from './components/loading.jsx'
import { StripeCheckoutForm } from './components/stripe_checkout_form.jsx'
import { InvoiceRequestForm } from './components/invoice_request_form.jsx'
import { ChargeCodeForm } from './components/charge_code_form.jsx'

import {loadStripe} from '@stripe/stripe-js';

import { Settings } from './settings.js';

let stripePk;
let stripePromise;
if (!Settings.martingale) {
  stripePk = document.querySelector('meta[name="stripe_publishable_key"]').content;
  stripePromise = loadStripe(stripePk);
}

const HeaderBox = props => {
  return <Text fontSize={4} fontWeight="bold">
    { props.children }
  </Text>
}

const CheckoutScreen = props => {
  const [clientSecret, setClientSecret] = useState("");

  let initialPaymentMethod = "card";
  if (Settings.martingale) {
    initialPaymentMethod = "charge-code";
  }

  const [paymentMethod, setPaymentMethod] = useState(initialPaymentMethod);
  let history = useHistory();

  if (!Settings.martingale) {
    useEffect(() => {
      console.log('fetching payment intent');
      console.log(props.cart)
      createCartPaymentIntent(props.cart)
             .then(res => {
               setClientSecret(res.data.client_secret);
             })
             .catch(error => {
               console.log("could not get payment intent");
             });
    }, [props.cart])
  }
  const cancel = () => {
    props.setCart({})
    history.push({ pathname: '/',
                   state: { flash: 'Your order has been canceled.' }});
  }

  const setPaymentMethodInvoice = () => setPaymentMethod('invoice');
  const setPaymentMethodCard = () => setPaymentMethod('card');

  const onSuccessfulPayment = (result) => {
    markCartPaid(props.cart)
      .then(res => {
        history.replace({ pathname: '/account/downloads',
                          state: { flash: 'Your order is being processed.' }});
      })
      .catch(error => {
        console.log("could not mark paid");
      });
  }

  const total = props.cart.total;

  let paymentBox = <Flex justifyContent='center'><Loading/></Flex>;

  if (paymentMethod == 'invoice') {
    paymentBox = <InvoiceRequestForm
                   cart={props.cart}
                   total={total}
                   cancel={cancel} />
  } else if (paymentMethod == 'charge-code') {
    paymentBox = <ChargeCodeForm
                   cart={props.cart}
                   total={total}
                   cancel={cancel} />
  } else if (clientSecret !== "") {
    paymentBox = <StripeCheckoutForm
                   stripePromise={stripePromise}
                   clientSecret={clientSecret}
                   total={total}
                   cancel={cancel}
                   onSuccessfulPayment={onSuccessfulPayment} />
  }

  return <NoSidebarLayout {...props}>
           <Box mt={6}
                pb={6}
                mx='auto'
                sx={{maxWidth: '500px'}}>

             <Flex
               flexDirection='column'
               py={4}
               px={5}
               sx={{borderRadius: "4px",
                    boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)'}}
               bg='white'>
               <FlashAlert />
               <HeaderBox>
                 { Settings.martingale ? 'Pay with charge code' : 'Enter your payment information to complete your order.' }
               </HeaderBox>
               {paymentBox}

             </Flex>


             { !Settings.martingale &&
             <Flex my={4} mx={3}>
               { paymentMethod == 'card' ? <Link onClick={setPaymentMethodInvoice}>Want to pay by invoice?</Link>
                 :  <Link onClick={setPaymentMethodCard}>Pay by credit card</Link> }
             </Flex> }
           </Box>
         </NoSidebarLayout>
}

export default CheckoutScreen;
