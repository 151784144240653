import axios from '../axios_config.jsx';
import Cookies from 'js-cookie'

export const getUser = () => {
  return axios.get('/api/shop/user');
}

export const updateUser = (attrs) => {
  return axios.put('/api/shop/user', { user: attrs });
}

export const getUserSessionFromCookies = () => {
  const user_cookies = Cookies.get('public_user_data');

  if (user_cookies !== undefined) {
    return JSON.parse(user_cookies);
  }
  return null;
}

export const linkChargeCode = (chargeCode) => {
  return axios.post('/api/shop/user/link_charge_code', { charge_code: chargeCode });
}

export const removeChargeCode = (chargeCode) => {
  return axios.post('/api/shop/user/remove_charge_code', { charge_code: chargeCode });
}
