import React from 'react'
import ReactDOM from 'react-dom'
import * as _ from 'lodash'

import {Link as RouterLink} from 'react-router-dom'

import styled from '@emotion/styled'
import { Image, Text, Flex, Box, Button, Link, Heading } from 'rebass'
import {NavLink, NavButton} from './nav_elements.jsx'

const LogoBlock = (props) => {
  if (props.logoUrl) {
    return <Link to='/' as={RouterLink}><Image src={props.logoUrl} sx={{ maxHeight: '90px' }} /></Link>
  } else {
    return null;
  }
}


export default function NoSidebarHeader(props) {
  let manageShopButton;
  if (props.userSession && props.userSession.role == 'shop_admin') {
    if (props.dataProductId) {
      manageShopButton = <NavButton variant='darkbg.outline' as={Link} href={`/admin/data_products/${props.dataProductId}`}>Manage shop</NavButton>
    } else {
      manageShopButton = <NavButton variant='darkbg.outline' as={Link} href={`/admin/home_screen/edit`}>Manage shop</NavButton>
    }
  }

  return <Flex mb={5}
               px={5}
               py={4}
               justifyContent='center'
               bg='secondary'
               color='white' >
           <Flex flexGrow={1} alignItems='center' sx={{maxWidth:'1400px'}}>
             <LogoBlock logoUrl={props.shopConfig.logo_url} />
             <Box mx='auto' />
             { props.userSession && props.userSession.role == 'shopper' &&
               <NavButton variant='darkbg.outline' to="/account/downloads" as={RouterLink}>Downloads</NavButton>
             }
             { props.userSession === null && <NavButton variant='darkbg.outline' to="/users/log_in" as={RouterLink}>Log in</NavButton> }
             {manageShopButton}
           </Flex>
         </Flex>
}
