import React, { useState } from 'react';

import { Redirect, useHistory, useLocation, Link as RouterLink } from 'react-router-dom'

import styled from '@emotion/styled';
import { Button, Link, Flex, Box, Text, Heading } from 'rebass';
import { Label, Input } from '@rebass/forms';
import FormLabelWithError from './components/form_label_with_error.jsx'
import NoSidebarHeader from './no_sidebar_header.jsx';
import axios from './axios_config.jsx';

export const RegistrationForm = (props) => {
  const [errorsByField, setErrorsByField] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);

  const register = (e) => {
    e.preventDefault();
    setFormDisabled(true);
    if (props.setFormSubmitting) {
      props.setFormSubmitting(true);
    }
    axios.post('/users', { user: { email: email, password: password, password_confirmation: passwordConfirm,
                                                 first_name: firstName, last_name: lastName }})
      .then(res=>{
        let role_string = 'shopper';

        props.setUserSession({
          userId: res.data.id,
          role: role_string
        })

        props.afterRegister();
      })
      .catch(error => {
        console.log(error)
        setFormDisabled(false);
        if (props.setFormSubmitting) {
          props.setFormSubmitting(false);
        }
        setErrorsByField(error.response.data['errors']);
      });
  }

  return (
    <form onSubmit={register}>
      <Box mb={4}>
        <FormLabelWithError label='First name' errorMessage={errorsByField['first_name']} />
        <Input disabled={formDisabled} onChange={e => setFirstName(e.target.value)} name='firstName' type='text' />
      </Box>


      <Box mb={4}>
        <FormLabelWithError label='Last name' errorMessage={errorsByField['last_name']} />
        <Input disabled={formDisabled} onChange={e => setLastName(e.target.value)} name='lastName' type='text' />
      </Box>


      <Box mb={4}>
        <FormLabelWithError label='Email' errorMessage={errorsByField['email']} />
        <Input disabled={formDisabled} autoComplete='off' onChange={e => setEmail(e.target.value)} name="email" type='text' />
      </Box>
      <Box mb={4}>
        <FormLabelWithError label='Password' autoComplete='off' errorMessage={errorsByField['password']} />
        <Input disabled={formDisabled} onChange={e => setPassword(e.target.value)} name='password' type='password' />
      </Box>

      <Box mb={4}>
        <FormLabelWithError label='Confirm password' errorMessage={errorsByField['password_confirmation']} />
        <Input disabled={formDisabled} onChange={e => setPasswordConfirm(e.target.value)} name='password_confirmation' type='password' />
      </Box>

      <Input type='submit' variant='buttons.primary' disabled={formDisabled}  my={3} py={3} sx={{width: '175px' }} value='Submit' mx="auto" />
    </form>
  );
}


const RegistrationPanel = props => {
  let history = useHistory();
  let location = useLocation();
  let cartEmpty = _.isEmpty(props.cart);

  let { from } = location.state || { from: { pathname: "/" } };

  let nextPagePathName = from
  let loginPath = '/users/log_in'
  if (location.state && location.state.nextPage) {
    nextPagePathName = location.state.nextPage
    loginPath = { pathname: loginPath, state: { nextPage: location.state.nextPage }}
  }

  const afterRegister = () => {
    history.replace({pathname: nextPagePathName,
                     state: { flash: "Sign up successful, you are now logged in." }});
  }

  if (props.userSession) {
    return(<Redirect to={{ pathname: "/", state: { flash: "You are already signed in." }}} />);
  } else {
    return(
      <Flex
        flexDirection='column'
        mt={3}
        mx='auto'
        sx={{width: ['100%', '80%', '60%'],
             maxWidth: '600px'}}>
        <Flex
          flexDirection='column'
          py={4}
          px={5}
          sx={{borderRadius: "4px",
               boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)'}}
          bg='white'>
          <Heading my={4}>Create an account</Heading>
          <RegistrationForm {...props} afterRegister={afterRegister} />
        </Flex>
        <Flex mt={4} mx={3}>
          <Link as={RouterLink} to={loginPath}>Already have an account? Sign in</Link>
        </Flex>
      </Flex>);
  }
}

export default function RegistrationScreen(props) {
  return <Box sx={{minHeight: '100vh'}}>
           <NoSidebarHeader {...props} />
           <RegistrationPanel {...props} />
         </Box>
}
