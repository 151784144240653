// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import * as _ from 'lodash'

import styled from '@emotion/styled'
import { ThemeProvider, useTheme } from 'emotion-theming'
import preset from '@rebass/preset'

import { Button, Text, Flex, Image, Box, Link, Heading } from 'rebass'

import { Label, Select } from '@rebass/forms'
import Color from 'color';

import LogInScreen from '../shop/log_in_screen.jsx'
import DownloadsScreen from '../shop/downloads_screen.jsx'

import RegistrationScreen from '../shop/registration_screen.jsx'
import ProfileScreen from '../shop/profile_screen.jsx'
import ChargeCodeScreen from '../shop/charge_code_screen.jsx'
import ForgotPasswordScreen from '../shop/forgot_password_screen.jsx'
import ResetPasswordScreen from '../shop/reset_password_screen.jsx'

import CheckoutScreen from '../shop/checkout_screen.jsx'
import NewSubscriptionScreen from '../shop/new_subscription_screen.jsx'
import CustomerInviteScreen from '../shop/customer_invite_screen.jsx'
import OrderPaymentScreen from '../shop/order_payment_screen.jsx'

import {NavLink, NavButton} from '../shop/nav_elements.jsx'
import { makeColorMap } from '../shop/colors.jsx'
import DataProductScreen from '../shop/data_product_screen.jsx'
import ApiDocumentationScreen from '../shop/api_documentation_screen.jsx'
import HomeScreen from '../shop/home_screen.jsx'

import ErrorBoundary from '../shop/components/error_boundary.jsx'

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://57276a64ab5d4eff9e0440cdc6b78358@o275072.ingest.sentry.io/5462728",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
  useLocation,
  useHistory
} from "react-router-dom";

import axios from '../shop/axios_config.jsx';
import { getUserSessionFromCookies } from '../shop/models/user.js'

const initialUserSession = getUserSessionFromCookies();

let stripePk;

if (document.querySelector('meta[name="stripe_publishable_key"]')) {
  stripePk = document.querySelector('meta[name="stripe_publishable_key"]').content;
}

const initialCartString = localStorage.getItem('cart');
let initialCart = {};
if (initialCartString) {
  try {
    initialCart = JSON.parse(initialCartString);
  } catch(e) {
    localStorage.removeItem('cart');
  }
}

export default function App(props) {
  const [userSession, setUserSession] = useState(initialUserSession)
  const [cart, setCartState] = useState(initialCart)

  const setCart = (attrs) => {
    setCartState(attrs)
    localStorage.setItem('cart', JSON.stringify(attrs))
  }
  const colorMap = props.colorMap;
  return (
    <Router basename={props.basename}>
      <ThemeProvider theme={props.siteTheme}>
        <ErrorBoundary>
          <Box bg={props.siteTheme.colors.mainBackground} fontSize={2} sx={{fontFamily: 'body', color: props.siteTheme.colors.darkText}}>
            <Switch>
              { userSession && userSession.role == 'shopper' &&
                <Route path="/account/downloads">
                  <DownloadsScreen dataProductVendorId={window.vendorId} {...props} />
                </Route> }

              { userSession && userSession.role == 'shopper' &&
                <Route path="/account/profile">
                  <ProfileScreen vendorId={window.vendorId} setUserSession={setUserSession} {...props}/>
                </Route> }

              { userSession && userSession.role == 'shopper' &&
                <Route path="/account/charge_Codes">
                  <ChargeCodeScreen vendorId={window.vendorId} setUserSession={setUserSession} {...props}/>
                </Route> }

    <Route path="/invite/*">
      <CustomerInviteScreen dataProductVendorId={window.vendorId}
                            userSession={userSession}
                            setUserSession={setUserSession}
                            cart={cart} {...props}
      />
    </Route>

    <Route path="/api">
      <ApiDocumentationScreen dataProductVendorId={window.vendorId}
                              userSession={userSession}
                              {...props}
      />
    </Route>

    <Route path="/orders/*/payment">
      <OrderPaymentScreen dataProductVendorId={window.vendorId}
                          userSession={userSession}
                          setUserSession={setUserSession}
                          cart={cart} {...props} />
    </Route>

    <Route path="/users/log_in">
      <LogInScreen dataProductVendorId={window.vendorId} userSession={userSession} setUserSession={setUserSession} cart={cart} {...props}/>
    </Route>

    <Route path="/users/registrations/new">
      <RegistrationScreen userSession={userSession} setUserSession={setUserSession} cart={cart} {...props}/>
    </Route>

    <Route path="/users/passwords/new">
      <ForgotPasswordScreen userSession={userSession} setUserSession={setUserSession} cart={cart} {...props}/>
    </Route>

    <Route path="/users/passwords/edit">
      <ResetPasswordScreen userSession={userSession} setUserSession={setUserSession} cart={cart} {...props}/>
    </Route>

    <Route path="/checkout">
      <CheckoutScreen cart={cart}
                      setCart={setCart}
                      userSession={userSession}
                      {...props} />
    </Route>

    <Route path="/subscribe">
      <NewSubscriptionScreen
        stripePk={stripePk}
        cart={cart}
        setCart={setCart}
        userSession={userSession}
        {...props} />
    </Route>

    <Route path="/products/:dataProductId">
      <DataProductScreen homeScreen={homeScreen} setCart={setCart} userSession={userSession} dataProductId={props.dataProductId} {...props}/>
    </Route>

    <Route path="/">
      <HomeScreen setCart={setCart} homeScreen={homeScreen} userSession={userSession} dataProductId={props.dataProductId} {...props}/>
    </Route>
            </Switch>
          </Box>
        </ErrorBoundary>
        </ ThemeProvider>
    </Router>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const homeScreen = window.homeScreen;
  const shopConfig = homeScreen.shop_configuration;
  const colorMap = makeColorMap({primary: shopConfig.primary_color,
                                 secondary: shopConfig.secondary_color,
                                 accent: shopConfig.accent_color});
  const siteTheme = _.merge(preset, {
    fontSizes: [10, 11, 14, 16, 20, 28],
    space: [
      0, 3, 6, 12, 24, 48, 96, 192
    ],
    breakpoints: [ '850px', '1600px' ],
    fonts: {
      body: 'Lato, system-ui, sans-serif',
      heading: 'Montserrat'

    },
    fontWeights: {
      body: 500,
      heading: 700
    },
    colors: colorMap,
    variants: {
      subheading: {
        fontFamily: 'Montserrat'
      },
      card: {
        boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)',
        borderRadius: '4px',
        padding: '16px',
        bg: 'white'
      },
      packageCard: {
        cursor: 'pointer',
      },
      link: {
        cursor: 'pointer',
      },
      darkbg: {
        bg: 'secondary',
        color: colorMap.textOnSecondary,
      },
      primarybg: {
        bg: 'primary',
        color: 'textOnPrimary',
      },
      danger: {
        bg: colorMap.red,
        color: 'white'
      },
      accentbg: {
        bg: colorMap.accent,
        color: 'white',
        borderRadius: '4px'
      },
      primaryBorder: {
        borderRadius: '4px',
        borderColor: colorMap.primaryInspiredTextColor,
        borderWidth: '2px',
        borderStyle: 'solid'
      },
      secondaryBorder: {
        borderRadius: '4px',
        borderColor: colorMap.secondaryInspiredTextColor,
        borderWidth: '2px',
        borderStyle: 'solid'
      },
    },
    buttons: {
      primary: {
        color: 'textOnPrimary',
        cursor: 'pointer',
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.4,
        },
      },
      outline: {
        variant: 'buttons.primary',
        color: 'primaryInspiredTextColor',
        bg: 'transparent',
        boxShadow: 'inset 0 0 2px',
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.4,
        },
      },
      secondaryOutline: {
        variant: 'buttons.secondary',
        color: 'secondaryInspiredTextColor',
        bg: 'transparent',
        boxShadow: 'inset 0 0 2px',
      },
      blackOutline: {
        variant: 'buttons.secondary',
        color: colorMap.darkText,
        bg: 'transparent',
        boxShadow: 'inset 0 0 2px',
      },
      darkbg: {
        filled: {
          variant: 'buttons.primary',
          color: colorMap.textOnSecondary,
          bg: 'secondary',
          boxShadow: 'inset 0 0 2px',
        },
        outline: {
          variant: 'buttons.primary',
          color: colorMap.textOnSecondary,
          bg: 'transparent',
          boxShadow: 'inset 0 0 2px',
        }
      },
      labelAction: {
        variant: 'buttons.darkbg.outline',
        padding: '2px 20px',
      },
    },
    forms: {
      label: {
        mb: 1,
        alignItems: 'center'
      },
      field: {
        borderColor: colorMap.secondaryInspiredTextColor,
        ':focus': {
          borderColor: colorMap.primary,
          outline: 'none',
          boxShadow: t => `0 0 0 2px ${t.colors.primary}`,
        },
        lineHeight: '1.8em'
      },
      input: {
        variant: 'forms.field',
        '&:disabled': {
          background: colorMap.muted
        }
      },
      select: {
        variant: 'forms.field',
      },
      textarea: {
        variant: 'forms.field',
      },
      checkbox: {
        variant: 'forms.field',
      }
    }
  });
  ReactDOM.render(
    <App shopConfig={shopConfig} siteTheme={siteTheme}
         basename={window.basename} />,
    document.body.appendChild(document.getElementById('wrapper')),
  )
})
