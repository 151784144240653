import React, { useState } from 'react';
import { Redirect, Link as RouterLink } from 'react-router-dom'

import styled from '@emotion/styled';
import { Button, Link, Flex, Box, Text, Heading } from 'rebass';
import { Label, Input } from '@rebass/forms';

import * as _ from 'lodash';
import NoSidebarHeader from './no_sidebar_header.jsx';
import axios from './axios_config.jsx';
import FlashAlert from './components/flash_alert.jsx'
import AlertBox from './components/alert_box.jsx'
import FormLabelWithError from './components/form_label_with_error.jsx'

const ForgotPasswordForm = props => {
    const [errorsByField, setErrorsByField] = useState({});
    const [email, setEmail] = useState("");
    const [formDisabled, setFormDisabled] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false); 

    const submitResetPasswordRequest = (e) => {
        axios.post('/users/password', { user: { email: email }})
             .then(res => {
               setErrorsByField({})
               setFormSuccess(true)
             })
             .catch(error => {
                setErrorsByField(error.response.data['errors'])
                setFormDisabled(false);
              });
        e.preventDefault();
    }

    let contents;

    if (formSuccess) {
        contents = <Redirect to={{ pathname: "/users/passwords/edit" }} />;
    } else {
        contents = <>
            <FlashAlert />
            <Heading my={4}>Reset your password</Heading>
            <form onSubmit={submitResetPasswordRequest}>
                <Box mb={3}>
                    <FormLabelWithError label='Email' errorMessage={errorsByField['email']} />
                    <Input disabled={formDisabled} onChange={e => setEmail(e.target.value)} name="email" type='text' />
                </Box>
                <Input type='submit' variant='buttons.primary' value='Submit' disabled={formDisabled} my={3} py={3} sx={{ width: '175px' }} mx="auto" />
            </form>
        </>
    }

    return (
        <Flex
            flexDirection='column'
            mt={3}
            mx='auto'
            sx={{
                width: ['100%', '80%', '60%'],
                maxWidth: '600px'
            }}>
            <Flex
                flexDirection='column'
                py={4}
                px={5}
                sx={{
                    borderRadius: "4px",
                    boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)'
                }}
                bg='white'>
                {contents}
            </Flex>
            <Flex mt={4} mx={3}>
                <Link as={RouterLink} to='/users/log_in'>Back</Link>
            </Flex>
        </Flex>
    );
}   

const ForgotPasswordScreen = (props) => { 
    return (
        <Box sx={{ minHeight: '100vh' }}>
            <NoSidebarHeader {...props} />
            <ForgotPasswordForm {...props} />
        </Box>
    )
}

export default ForgotPasswordScreen;