import React, { useState, useEffect } from 'react';

import { Heading, Button, Box, Flex, Link, Text } from 'rebass';

import { getBuyerApiKey } from './models/buyer.js';
import StyledTable from './components/styled_table.jsx'

import NoSidebarLayout from './no_sidebar_layout.jsx';

const SubHeading = props =>
  <Heading
    {...props}
    mt={4}
    mb={3}
    fontSize={'18px'}
  />

const Code = props =>
  <Text
    {...props}
    as='pre'
    my={3}
    sx={{
      fontFamily: 'monospace',
      p: 2,
      color: 'secondaryInspiredTextColor',
      bg: 'muted',
      overflowX: 'auto',
    }}
  />

const ApiDocumentationScreen = (props) => {
  let [apiKey, setApiKey] = useState(null);
  let [apiKeyIsVisible, setApiKeyIsVisible] = useState(false);

  let placeholderApiKey = 'YOUR-API-KEY';

  let apiKeyToShow = placeholderApiKey;
  if (apiKeyIsVisible && apiKey) {
    apiKeyToShow = <strong>{apiKey}</strong>;
  }

  let apiUrl = window.location.protocol + "//" + window.location.host + "/api/buyer";

  const fetchApiKey = (callback) => {
    getBuyerApiKey()
      .then(res => {
        setApiKey(res.data.buyer_api_key);
        callback();
      })
      .catch (error => {
        alert("Could not fetch API key.");
      })
  }

  const showApiKey = () => {
    if (apiKey) {
      setApiKeyIsVisible(true);
    } else {
      fetchApiKey(() => setApiKeyIsVisible(true));
    }
  }

  const hideApiKey = () => {
    setApiKeyIsVisible(false);
  }

  const sampleResponse = `
{
  "total_records": 5,
  "downloads": [
    {
      "id": "900e2bd8-b7eb-4907-a0e9-a8d7f73f6005",
      "download_url": "https://syndetic-datashop.s3.amazonaws.com/...",
      "as_of": "2021-02-05T17:35:18.921-05:00",
      "product": {
        "id": "9f96435c-b88d-4cd2-9eba-a044a1450c64",
        "name": "Sample product"
      }
    },
    ...
  ]
}
`;


  let toggleApiLink;
  if (props.userSession && props.userSession.role == 'shopper') {
    toggleApiLink = apiKeyIsVisible ? <Link onClick={hideApiKey}>Hide API Key</Link> : <Link onClick={showApiKey}>Show API Key</Link> ;
  }

  return <NoSidebarLayout {...props}>
           <Box mx='auto' sx={{maxWidth: '1200px'}} pb={5}>
             <Heading fontSize={5} mb={4}>API Documentation</Heading>
             <Heading fontsize={4} mb={3}>Downloading data</Heading>

             <Text>You can access all of your purchased data via API.</Text>
             <SubHeading mb={2}>Headers</SubHeading>
             <Text>
               Requests must include your API key:
             </Text>
             <Code>X-Api-Key: {apiKeyToShow}</Code>
             {toggleApiLink}
             <SubHeading>Request</SubHeading>
             <Code>
               curl -H "X-Api-Key: {apiKeyToShow}" {apiUrl + '/downloads'}
             </Code>

             <SubHeading>Request parameters</SubHeading>
             <StyledTable>
               <thead>
                 <tr>
                   <th>Parameter</th>
                   <th>Type</th>
                   <th>Description</th>
                 </tr>
               </thead>
               <tbody>
                 <tr>
                   <td>page</td>
                   <td>Integer (optional)</td>
                   <td>Results are ordered with the most recent downloads first and a max page size of 20. Use this parameter (defaults to 1) to access older downloads.</td>
                 </tr>
               </tbody>
             </StyledTable>

             <SubHeading>Sample response</SubHeading>
             <Code>
               {sampleResponse}
             </Code>

             <SubHeading>Response schema</SubHeading>
             <StyledTable>
               <thead>
                 <tr>
                   <th>Field</th>
                   <th>Description</th>
                 </tr>
               </thead>

               <tbody>
                 <tr><td>id</td><td>Unique (UUID) identifier for the download.</td></tr>
                 <tr><td>download_url</td><td>Download URL to access the dataset. This link is unique to this API response and expires after 15 minutes.</td></tr>
                 <tr><td>as_of</td><td>Timestamp the download was generated.</td></tr>
                 <tr><td>product.id</td><td>Unique (UUID) identifier of the product this download is for.</td></tr>
                 <tr><td>product.name</td><td>Name of the product this download is for.</td></tr>
               </tbody>
             </StyledTable>

         <SubHeading>Retention and schedule</SubHeading>
         <Text>Active subscriptions generate new downloads daily. Files are purged when they are more than 30 days old, except that the first one of each month is never purged.
         </Text>
         <Text>
           Files generated from one-off purchases persist forever.
         </Text>
       </Box>
     </NoSidebarLayout>
}

export default ApiDocumentationScreen;
