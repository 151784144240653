import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useTheme } from 'emotion-theming'

import { Flex, Box, Button, Text } from 'rebass'
import { Label, Input} from '@rebass/forms'

import strftime from 'strftime';

const NumericRangeFilter = props => {
  const theme = useTheme();

  let currentValue = props.currentValue;

  const emptyRangeState = {start: "", end: ""};

  const [selectedStart, setSelectedStart] = useState("");
  const [selectedEnd, setSelectedEnd] = useState("");

  useEffect(() => {
    let currentValue = props.currentValue;
    if (currentValue === undefined || currentValue == null || currentValue == "" ||
        _.isEmpty(currentValue)) {
    } else {
      if (Array.isArray(currentValue)) {
        currentValue = currentValue[0];
      }
      const [startString, endString] = currentValue.split("_")
      setSelectedStart(startString);
      setSelectedEnd(endString);
    }
  }, [props.currentValue]);

  let fieldName = props.filter.field_display_name;

  const clearInput = () => {
    setSelectedStart("");
    setSelectedEnd("");
    props.setFilterValue(null);
  }

  const submit = () => {
    props.setFilterValue(selectedStart + "_" + selectedEnd)
  }

  const valueIsSelected = () => {
    return (selectedStart !== "" && selectedEnd !== "");
  }

  const setStart = (e) => {
    setSelectedStart(e.target.value)
  }

  const setEnd = (e) => {
    setSelectedEnd(e.target.value)
  }

 return <Box sx={{position: 'relative'}} mb={4} {...props}>
  <Flex
  sx={{minHeight: '27px'}}
  mb={2}>
  <Box><Label sx={{textTransform: 'capitalize', height: '100%'}} m={0}>{fieldName}</Label></Box>
  <Box mx='auto' />
  <Box>
  {valueIsSelected() &&
   <>
     <Button onClick={submit} variant='labelAction'>Apply</Button>
     <Button onClick={clearInput} variant='labelAction'>Clear</Button>
   </>
  }
  </Box>
  </Flex>

    <Flex justifyContent='space-between'
                    flexGrow={1} sx={{minHeight: '36px'}} alignItems='center'>
    <Input  color='secondaryLightTextMuted'
            p={'8px'}
            bg='secondaryPlaceholder'
            sx={{borderRadius: '4px',
                 borderWidth: '1px',
                 borderStyle: 'solid',
                 userSelect: 'none',
            }}
  value={selectedStart} onChange={setStart}/>
  <Text>–</Text>
      <Input  color='secondaryLightTextMuted'
              p={'8px'}
              bg='secondaryPlaceholder'
              sx={{borderRadius: '4px',
                   borderWidth: '1px',
                   borderStyle: 'solid',
                   userSelect: 'none',
              }}
        value={selectedEnd} onChange={setEnd} />
  </Flex>
 </Box>
};

export default NumericRangeFilter;
