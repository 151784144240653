import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { Li, Button, Text, Flex, Box, Link, Heading } from 'rebass'

import { Label, Input, Checkbox } from '@rebass/forms'

import { validateCart, createCart, createDraftCart } from './models/cart.jsx'

import { curFmt } from './util.js'

import { Settings } from './settings.js'

const CheckoutRow = props => {
  return(
    <Flex
      alignItems='center'
      my={2}
      {...props}>
      {props.left}
      <Box mx='auto' />
      {props.right}
    </Flex>
  );
}

const Divider = props => (
  <Box
    {...props}
    mx={4}
    my={3}
    as='hr'
    bg='lightGray'
    sx={{
      border: 0,
      height: 2
    }}
  />
)

const SubHeading = props => (
  <Heading
    {...props}
    fontSize={3}
    color='secondaryInspiredTextColor' />
)

export const DraftCart = props => {
  const [selectedPackages, setSelectedPackages] = useState(new Set());
  const [searchName, setSearchName] = useState("");
  const [licenseHolder, setLicenseHolder] = useState("");
  const [tosAccepted, setTosAccepted] = useState(false);
  const [cartErrors, setCartErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  let history = useHistory();

  const toggleTosAccepted = () => setTosAccepted(!tosAccepted)

  let isShopAdmin = false;
  let userRole;
  if (props.userSession) {
    userRole = props.userSession.role;
    if (props.userSession.role == 'shop_admin') {
      isShopAdmin = true;
    }
  }

  const subscription = props.dataProduct.subscription_plan;
  console.log(subscription)
  const checkout = () => {
    setSubmitting(true);
    const attrs = {
      selected_package_ids: Array.from(selectedPackages),
      search_name: searchName,
      license_holder: licenseHolder,
      tos_accepted: tosAccepted,
      filters: props.appliedFilters,
      data_product_id: props.dataProduct.id
    }

    const cartValidation = validateCart(attrs, userRole)
    if (cartValidation === true) {
      let nextPage = '/checkout';
      if (subscription) {
        nextPage = '/subscribe';
      }

      if (!props.userSession) {
        nextPage = { pathname: '/users/log_in',
                     state: { flash: 'Log in or create an account to complete your order.',
                              nextPage: nextPage }};
      }

      createDraftCart(attrs)
        .then(res =>
          {
            if (isShopAdmin) {
              if (props.dataProduct.subscription_product) {
                window.location.href = `/admin/subscriptions/new?cart_id=${res.data.id}`;
              } else {
                window.location.href = `/admin/orders/new?cart_id=${res.data.id}`;
              }
            } else {
              props.setCart(res.data);
              history.replace(nextPage);
            }
          }
        )
        .catch(error => {
          setSubmitting(false);
          console.log(error.response);

          if (error.response.data && error.response.data['errors']) {
            setCartErrors(error.response.data['errors'])
          } else {
            setCartErrors(["Something went wrong creating this order."]);
          }
        });

    } else {
      setSubmitting(false);
      setCartErrors(cartValidation);
    }
  }

  const togglePackage = (pkg) => {
    const newSet = new Set(selectedPackages)
    if (newSet.has(pkg.id)) {
      newSet.delete(pkg.id);
    } else {
      newSet.add(pkg.id);
    }
    setSelectedPackages(newSet);
  }

  let subtotalDollars = Number(0)
  let pricePerRecord = null;
  let recordsSelected = 0;
  props.searchResult.packages.forEach(pkg => {
    if(selectedPackages.has(pkg.id)) {
      if (subscription) {
        subtotalDollars += pkg.price_per_row_dollars;
      } else {
        subtotalDollars += (pkg.total_records * pkg.price_per_row);
        recordsSelected += pkg.total_records;
        if (pricePerRecord && pkg.price_per_row != pricePerRecord) {
          pricePerRecord = 'varies';
        } else if (pricePerRecord === null) {
          pricePerRecord = pkg.price_per_row;
        }
      }
    }
  });

  if (pricePerRecord !== 'varies' && pricePerRecord !== null) {
    pricePerRecord = curFmt.format(pricePerRecord);
  }

  const discountDollars = -0.00;
  let totalDollars = subtotalDollars + discountDollars;
  if (totalDollars < 0) {
    totalDollars = 0;
  }

  const checkoutRows = _.map(props.searchResult.packages, (pkg) => {
    const total_records = Intl.NumberFormat().format(pkg.total_records);
    let right = <Text textAlign='right'>{total_records}</Text>;
    if (subscription) {
      if (subscription.data_product_packages[pkg.id]) {
        right = <Text textAlign='right'>
                  {curFmt.format(subscription.data_product_packages[pkg.id].price_per_month_dollars)}
                </Text>
      } else {
        right = <Text textAlign='right'>—</Text>
      }
    }

    return <CheckoutRow key={pkg.id} left={<Label sx={{width: 'auto'}}>
                                             <Checkbox name='package_core'
                                                       checked={selectedPackages.has(pkg.id)}
                                                       onChange={e => togglePackage(pkg)} />
                                             {pkg.name}
                                           </Label>}
                        right={right}
           />;
  });


  let cartErrorBox;
  if (cartErrors.length > 0) {
    cartErrorBox =
      <Box mb={3}>
        {cartErrors.map((e, i) => <Text key={i} color='red'>{e}</Text>)}
      </Box>
  }

  let pricingBox;

  if (subscription) {

  } else {
    pricingBox = <>
                   <Divider />

                   <Box px={4} >
                   <SubHeading mb={2}>Pricing</SubHeading>

                   <CheckoutRow left='Records Selected:'
                                right={Intl.NumberFormat().format(recordsSelected)} />

                   <CheckoutRow left='Price per Record:'
                                right={pricePerRecord} />
                   <CheckoutRow left='Subtotal:'
                                right={curFmt.format(subtotalDollars)} />
                   {false && <CheckoutRow color='primary'
                                          fontWeight='bold'
                                          left='Discount:'
                                          right={curFmt.format(discountDollars)} />}
                   <CheckoutRow color='secondary'
                                fontWeight='bold'
                                left='Total:'
                                right={curFmt.format(totalDollars)} />
                   </Box>
                 </>;
  }

  let checkoutCtaText = 'Checkout';
  if (isShopAdmin) {
    if (props.dataProduct.subscription_product) {
      checkoutCtaText = 'Build subscription'
    } else {
      checkoutCtaText = 'Build order'
    }
  }

  return (
    <Box>
      <Flex variant='primarybg' px={4} py={'24px'} alignItems='center' >
        <Heading>Purchase Data</Heading>
        <Box mx='auto' />
        <Link color='textOnPrimary' fontSize={4} onClick={props.toggleModal}>✕</Link>
      </Flex>
      <Box pt={3} px={4}>
        <Flex mb={2}>
          <SubHeading>Dataset Selection</SubHeading>
          <Box mx='auto' />
          <SubHeading>{subscription ? 'Price per month' : 'Records count'}</SubHeading>
        </Flex>

        <Box>{checkoutRows}</Box>
      </Box>

      {pricingBox}

      {!isShopAdmin && <Divider /> }
      <Box px={4} mb={4}>
        {!isShopAdmin && <>
                           <SubHeading mb={2}>Order Details</SubHeading>

                           {!subscription &&
                            <Box mb={3}>
                              <Label>Name your search</Label>
                              <Input autoComplete='off' name="search_name" type='text' value={searchName} onChange={e => setSearchName(e.target.value)} />
                            </Box>}

                           { !Settings.martingale &&
                           <Box mb={3}>
                             <Label>License holder</Label>
                             <Input name="license_holder" autoComplete='off' type='text' value={licenseHolder} onChange={e => setLicenseHolder(e.target.value)} />
                           </Box> }

                           <Box mb={3}>
                             <Label>
                               <Checkbox onChange={toggleTosAccepted} checked={tosAccepted} name="license" id="license" />
                               I have read and understand the license <Link onClick={e => e.stopPropagation()} target="_blank" href={props.dataProduct.terms_and_conditions_url} px={1}>Terms and Conditions.</Link>
                             </Label>
                           </Box>
                         </>
        }
        {cartErrorBox}
      </Box>


      <Flex bg='muted' p={3} justifyContent='flex-end'
            alignItems='center' >
        {!isShopAdmin && <Button mr={3} sx={{width: '200px'}}
                                 onClick={props.openContactSales}
                                 variant='secondaryOutline'>{Settings.contactSalesString}</Button>}
        <Button disabled={submitting} onClick={checkout} sx={{width: '200px'}} >{checkoutCtaText}</Button>
      </Flex>
    </Box>
  );
}
