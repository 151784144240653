import React, {useState, useEffect} from 'react';
import { Text, Box, Flex } from 'rebass'
import {CubeGrid} from 'styled-spinkit';
import  styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

const MyCubeGrid = styled(CubeGrid)`
    && {
        margin: 0 auto;
    }
`;

const Loading = (props) => {
  const [hidden, setHidden] = useState(true);

  const theme = useTheme()

   useEffect(() => {
     const timer = setTimeout(() => setHidden(false), 100);
     return () => clearTimeout(timer);
   }, []);


  if (hidden) {
    return null;
  } else if (props.children) {
    return <>{props.children}</>
  } else {
    return (
      <MyCubeGrid {...props} sx={{margin: 0}} size={200} name='cube-grid' color={theme.colors.accent} />
    );
  }
}

export default Loading;
