import React, { useState, useEffect } from 'react';

import { useHistory, Link as RouterLink } from 'react-router-dom'

import { createInvoiceRequest } from '../models/invoice_request.js'

import FormLabelWithError from './form_label_with_error.jsx'

import { Button, Text, Flex, Box } from 'rebass'

import { Label, Input } from '@rebass/forms'

const InputWithLabel = (props) => {
    return(<Box sx= {{ letterSpacing: '0.025em' }}
                mb={3}>
             <FormLabelWithError label={props.fieldLabel} errorMessage={props.errorsByField[props.field]} />
             <Input sx={{fontSize: '18px', color: '#424770'}} mt={'10px'}
                    disabled={props.disabled} autoComplete='off' name={props.field} type='text' value={props.fieldValue} onChange={e => props.setVal(e.target.value)} />
           </Box>);
  }

export const InvoiceRequestForm = (props) => {
  const [billing_email, setBillingEmail] = useState("");
  const [address_line_1, setAddressLine1] = useState("");
  const [address_line_2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");

  const [formDisabled, setFormDisabled] = useState(false);
  const [errorsByField, setErrorsByField] = useState({});
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  let history = useHistory();

  const submitForm = (e) => {
    e.preventDefault();
    setFormDisabled(true);
    setErrorsByField({});
    createInvoiceRequest({
      billing_email,
      address_line_1,
      address_line_2,
      city,
      state,
      zipcode,
      country,
      cart_id: props.cart.id
    })
      .then(res => {
        history.replace({ pathname: '/account/downloads',
                          state: { flash: 'Thank you for your order. An invoice is being prepared.' }});

      }).catch(error => {
        console.log(error)
        setFormDisabled(false)
        if (error.response) {
          setErrorsByField(error.response.data['errors']);
        }
      })
  }

  let formBlock;
  if (submissionSuccessful) {
    formBlock = <Text textAlign='center' fontSize={3} p={3}>
                  Your order has been received and an invoice will be sent out shortly.
                </Text>
  } else {
    formBlock = (
      <form onSubmit={submitForm} >
        <Box my={3} mb={4}>
          <InputWithLabel disabled={formDisabled} field='billing_email' fieldLabel='Billing email' errorsByField={errorsByField} setVal={setBillingEmail} fieldValue={billing_email} />
          <InputWithLabel disabled={formDisabled} field='address_line_1' fieldLabel='Address' errorsByField={errorsByField} setVal={setAddressLine1} fieldValue={address_line_1} />
          <InputWithLabel disabled={formDisabled} field='address_line_2' fieldLabel='Address (line 2)' errorsByField={errorsByField} setVal={setAddressLine2} fieldValue={address_line_2} />
          <InputWithLabel disabled={formDisabled} field='city' fieldLabel='City' errorsByField={errorsByField} setVal={setCity} fieldValue={city} />
          <InputWithLabel disabled={formDisabled} field='state' fieldLabel='State' errorsByField={errorsByField} setVal={setState} fieldValue={state} />
          <InputWithLabel disabled={formDisabled} field='country' fieldLabel='Country' errorsByField={errorsByField} setVal={setCountry} fieldValue={country} />
          <InputWithLabel disabled={formDisabled} field='zipcode' fieldLabel='Zipcode' errorsByField={errorsByField} setVal={setZipcode} fieldValue={zipcode} />
          </Box>

          <Flex>
            <Button onClick={props.cancel}
                    disabled={formDisabled}
                    type="button"
                    variant='outline'
                    to="/"
                    as={RouterLink}>
              Cancel
            </Button>

            <Box mx='auto' />

            <Button type="submit" variant='primary' disabled={formDisabled} onClick={submitForm} sx={{width: '200px'}} >Submit</Button>
          </Flex>
      </form>
    );
  }
  return <Box>{formBlock}</Box>;
}
