import React from 'react'

import { Flex, Text, Box } from 'rebass'
import { Label } from '@rebass/forms'

const FormLabelWithError = props => {
  let errorMessageBox;
  if (props.errorMessage && props.errorMessage.length > 0) {
    errorMessageBox = <Text color='red'>{props.errorMessage[0].toLowerCase()}</Text>
  }

  return <Flex>
           <Label sx={{width: 'auto'}}>{props.label}</Label>
           <Box mx='auto'/>
           {errorMessageBox}
         </Flex>
}

export default FormLabelWithError;
