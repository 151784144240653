import axios from '../axios_config.jsx';
import { Settings } from '../settings.js';

export const validateCart = (cartAttrs, userRole) => {
  let errors = []
  if (cartAttrs.selected_package_ids.length === 0) {
    errors.push('Select at least one package');
  }

  if (userRole !== 'shop_admin') {
    if (!Settings.martingale) {
      if (cartAttrs.license_holder === "") {
        errors.push("License holder is required");
      }
    }

    if (cartAttrs.tos_accepted === false) {
      errors.push("Terms and conditions must be accepted" );
    }
  }

  if (errors.length === 0) {
    return true;
  } else {
    return errors;
  }
}

export const createDraftCart = cartAttrs => {
  console.log(cartAttrs);
  return axios.post('/api/shop/carts', { cart: cartAttrs });
}

export const createCartPaymentIntent = cartAttrs => {
  return axios.post(`/api/shop/carts/${cartAttrs.id}/payment_intent`)
}

export const markCartPaid = cartAttrs => {
  return axios.post(`/api/shop/carts/${cartAttrs.id}/payment_successful`)
}

export const createStripeSubscriptionFromCart = (cartAttrs, paymentMethodId) => {
  return axios.post(`/api/shop/carts/${cartAttrs.id}/create_stripe_subscription`,
                    { payment_method_id: paymentMethodId })
}
