import React from 'react';
// https://reactjs.org/docs/error-boundaries.html

import { Text, Box } from 'rebass'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    console.log(this.state);
    if (this.state.hasError) {
      return (<Box
                p={3}
                variant='card'
                sx = {{
                  margin: '50px auto',
                  width: '500px',
                  borderColor: 'darkText',
                  borderRadius: '4px',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  textAlign: 'center',
                  position: 'relative',
                  color: 'red',
                  'zIndex': 2
                }}
                {...this.props}>
                <Text>We're sorry, but something went wrong.
                Please refresh the page and try again.</Text>
              </Box>
             );
    } else {
      return this.props.children; 
    }
  }
}

export default ErrorBoundary;
