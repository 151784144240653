import React, { useState } from 'react'

import { Link as RouterLink } from 'react-router-dom'

import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import Color from 'color';
import { Button, Text, Flex, Box, Link, Heading } from 'rebass'
import * as _ from 'lodash'
import { Tooltip } from './components/tooltip.jsx'
import { Settings } from './settings.js'

const PreviewTable = styled.table`
  {
    border: 1px solid rgba(34, 36, 38, 0.1);
    border-radius: 4px;
    background-color: white;
    border-collapse:collapse;
    border-spacing:0;
    width: 100%;
    tbody tr td {
      border: 1px solid rgba(34, 36, 38, 0.1);
      padding: 20px;
      white-space: nowrap;
      overflow: hidden;
      max-width: 450px;
    }
    tbody tr td.redacted {
         pointer-events: none;
         color: ${props => Color(props.theme.colors.darkText).alpha(0.3).string()};
    }
    tbody tr td:hover {
      max-width : initial;
    }
    thead tr {
     background-color: ${props => Color(props.theme.colors.secondary).alpha(0.05).string()};
    }
    thead tr th {
     cursor: pointer;
     text-align: left;
     padding: 10px 20px;
     color: ${props => props.theme.colors.secondaryInspiredTextColor};
     font-weight: bold;
     border: 1px solid rgba(34, 36, 38, 0.1);
    }
  }
`;

const Redacted = (props) => {

  return (<Text sx={{ filter: 'blur(3px)' }}>
    nothing to see here
  </Text>);
}

const PreviewTd = (props) => {
  if (props.data.redacted) {
    return (<td className="redacted"><Redacted /></td>)
  } else {
    return (<td>{props.data.value}</td>)
  }
}

const PackagePreviewHeaderCell = (props) => {
  const field = props.field
  let displayName = field
  const overlay = props.fieldOverlay;
  let definition;

  if (overlay) {
    if (overlay.field_display_name) {
      displayName = overlay.field_display_name
    }
    definition = overlay.definition;
  }

  return <th style={{ whiteSpace: 'nowrap' }}>
    <Flex>
      <Text mr={2}>{displayName}</Text>
      {definition &&
        <Box>
          <Tooltip size={18}>{definition}</Tooltip>
        </Box>
      }
    </Flex>
  </th>
}

function PackagePreview(props) {
  let previewRows = props.previewRows

  if (_.isEmpty(previewRows)) {
    return <em>No results</em>
  } else {
    const header = previewRows[0].map(x => <PackagePreviewHeaderCell key={x.column} field={x.column} fieldOverlay={props.dataProductFields[x.column]} />);
    const body = previewRows.map((x, i) => {
      return (
        <tr key={i}>{x.map((v, i) =>
          <PreviewTd key={i} data={v} />)}
        </tr>)
    })
    return <PreviewTable>
      <thead>
        <tr>
          {header}
        </tr>
      </thead>

      <tbody>
        {body}
      </tbody>
    </PreviewTable>
  }
}

const PackagePreviewTab = styled(Box)`
  cursor: pointer;
  &:hover {
    border-bottom-width: 3px
  }
`

function PackagePreviewNavigator(props) {
  const theme = useTheme()
  const navLinks = props.packages.map(pkg => {
    let fontWeight = 'normal'
    let borderColor = theme.colors.secondaryInspiredTextColor
    let borderBottom = '1px solid'
    if (pkg && props.activePackage.id === pkg.id) {
      fontWeight = 'bold'
      borderBottom = '3px solid'
    }

    return (
      <PackagePreviewTab
        onClick={e => props.setActivePackageId(pkg.id)}
        px={4} sx={{ borderBottom: borderBottom, borderColor: borderColor }} key={pkg.id}>
        <Link
          color={theme.colors.secondaryInspiredTextColor}
          variant='subheading'
          fontWeight={fontWeight}
          fontSize={4}>{pkg.name}
        </Link>
      </PackagePreviewTab>);
  });

  return (
    <Flex mb={4} flexGrow={1}>
      {navLinks}
      <Box flexGrow={1} sx={{
        borderBottom: '1px solid',
        borderColor: theme.colors.secondaryInspiredTextColor
      }} />
    </Flex>
  );
}

const SearchCTA = props => {
  const theme = useTheme()
  let boxWidth = '600px';
  if (props.packages.length > 1) { boxWidth = '800px' }
  const packageRecords = props.packages.map(pkg => <Text
    key={pkg.id}
    color={pkg == props.activePackage ? 'secondaryInspiredTextColor' : 'darkText'}
    fontWeight={pkg == props.activePackage ? 'bold' : 'normal'}
    px={4}>{`${pkg.name}: ${Intl.NumberFormat().format(pkg.total_records)}`}</Text>);
  let totalRecords = 0;
  props.packages.forEach(pkg => totalRecords += pkg.total_records);

  let revisionBlurb;
  if (props.packages.length > 0) {
    console.log(props.packages)
    revisionBlurb = ` from the ${props.packages[0].current_revision_name} release`
  }

  let subscriptionBlurb;
  let getDataButton = <Flex sx={{ position: 'absolute', bottom: '-20px' }}>
    <Box bg={theme.colors.mainBackground} px={3} />
    <Button sx={{ width: '200px' }}
      onClick={props.toggleModal}>Get Data</Button>
    <Box bg={theme.colors.mainBackground} px={3} />
  </Flex>;

  if (props.subscription) {
    getDataButton = null;
    subscriptionBlurb = <Box>
      You are <Link as={RouterLink} to='/account/downloads'>subscribed</Link> to this dataset.
    </Box>
  } else if (Settings.martingale && props.userSession && props.userSession.role == 'shop_admin') {
    getDataButton = null;
  }

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      mx='auto'
      sx={{ maxWidth: boxWidth, position: 'relative' }}
      py={4}
      variant='primaryBorder'>
      <Heading sx={{ textTransform: 'uppercase' }}
        mb={3}
        color='primaryInspiredTextColor'>Search Results</Heading>
      <Text mb={3} color='gray'>
        {`${Intl.NumberFormat().format(totalRecords)} total records${revisionBlurb}.`}
      </Text>

      {props.packages.length > 1 &&
        <Flex justifyContent='space-between' mb={3}>
          {packageRecords}
        </Flex>}
      {subscriptionBlurb}
      {getDataButton}
    </Flex>
  );
}

export const SearchResults = props => {

  if (_.isEmpty(props.searchResult.packages)) {
    return <Box mx={5} >
      <Heading mb={4} fontSize={5}>{props.product.name}</Heading>
      <Text>No results</Text>
    </Box>
  } else {

    const activePkg = _.find(props.searchResult.packages, (p) => p.id == props.activePackageId) || props.searchResult.packages[0];
    return <Box flexGrow={1} mx={5} >
      <Heading mb={4} fontSize={5}>{props.product.name}</Heading>
      <Box mb={4} sx={{ 'overflowX': 'scroll' }}
        key={activePkg.id}>
        <PackagePreviewNavigator
          setActivePackageId={props.setActivePackageId}
          packages={props.searchResult.packages}
          activePackage={activePkg} />
        <PackagePreview previewRows={activePkg.preview} dataProductFields={props.product.fields} />
      </Box>

      <SearchCTA userSession={props.userSession}
        subscription={props.product.subscription}
        toggleModal={props.toggleModal}
        packages={props.searchResult.packages}
        activePackage={activePkg} />
    </Box>
  }
}
