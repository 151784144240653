import React from 'react';
import { Text } from 'rebass'

import {curFmt} from '../util.js'

const Price = (props) => {
  if (props.price != null) {
    return (
      <Text fontSize={5} color='accentInspiredTextColor'><strong>{props.price}</strong>/{props.unit}</Text>
    )
  } else {
    return null;
  }
}

export default Price;
