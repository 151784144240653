import React from 'react';
import { Flex, Box, Heading } from 'rebass'
import { useTheme } from 'emotion-theming'

export function TabNav(props) {
  const theme = useTheme()
  return (
    <Flex mb={4} flexGrow={1} >
      {props.children}
      <Box flexGrow={1} sx={{borderBottom: '1px solid',
                             borderColor: theme.colors.secondaryLight}}  />
    </Flex>);
}

export function TabLink(props) {
  const theme = useTheme()
  let fontWeight='normal'
  let borderColor= theme.colors.secondaryInspiredTextColor
  let borderBottom='1px solid'

  if (props.activated) {
    fontWeight = 'bold'
    borderBottom='3px solid'
  }

  return(
    <Box px={4}
         variant='subheading'
         sx={{borderBottom: borderBottom, borderColor: borderColor, textDecoration: 'none'}}
         color={borderColor}
         fontSize={4}
         fontWeight={fontWeight}
         {...props}>
      {props.children}
    </Box>);
}
