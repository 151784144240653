import React from 'react';

import { Link, Image } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'

const LogoBlock = (props) => {
  return <Link as={RouterLink} to="/"><Image src={props.logoUrl} sx={{ maxHeight: '90px' }} /></Link>
}

export default LogoBlock;
