import React, { useState, useEffect } from 'react'

import { Button, Text, Flex, Box, Link, Heading } from 'rebass'

import { Label, Input, Textarea } from '@rebass/forms'
import FormLabelWithError from './components/form_label_with_error.jsx'

import { createSalesLead } from './models/sales_lead.js'

import { Settings } from './settings.js'

const InputWithLabel = (props) => {
  return(<Box mb={3}>
    <FormLabelWithError label={props.fieldLabel} errorMessage={props.errorsByField[props.field]} />
    <Input disabled={props.disabled} autoComplete='off' name={props.field} type='text' value={props.fieldValue} onChange={e => props.setVal(e.target.value)} />
  </Box>);
}

const ContactSalesScreen = (props) => {
  const forceContactSales = props.shopConfig.force_contact_sales;
  let memoLabel = 'Memo';
  if (props.shopConfig.memo_label) {
    memoLabel = props.shopConfig.memo_label;
  }

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);
  const [memo, setMemo] = useState("");
  const [errorsByField, setErrorsByField] = useState({});
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    setFormDisabled(true);
    setErrorsByField({});
    createSalesLead({
      name,
      company,
      email,
      phone_number,
      memo,
      filters: props.appliedFilters,
      data_product_id: props.dataProduct.id })
      .then(res => {
        setSubmissionSuccessful(true);
      }).catch(error => {
        console.log(error)
        setFormDisabled(false)
        setErrorsByField(error.response.data['errors']);
      })
  }

  let formBlock;
  if (submissionSuccessful) {
    formBlock = <Text textAlign='center' fontSize={3} p={5}>
                  Thanks for your submission. Someone will be in touch shortly.
                </Text>
  } else {
    formBlock = (
      <>
      <Box my={4} px={4} mb={4}>
      <InputWithLabel disabled={formDisabled} field='name' fieldLabel='Name' errorsByField={errorsByField} setVal={setName} fieldValue={name} />
      {!Settings.martingale &&
       <InputWithLabel disabled={formDisabled} field='company' fieldLabel='Company' errorsByField={errorsByField} setVal={setCompany} fieldValue={company} /> }
      <InputWithLabel disabled={formDisabled}  field='email' fieldLabel='Email' errorsByField={errorsByField} setVal={setEmail} fieldValue={email} />
      { !Settings.martingale &&
      <InputWithLabel disabled={formDisabled} field='phone_number' fieldLabel='Phone Number' errorsByField={errorsByField} setVal={setPhoneNumber} fieldValue={phone_number} /> }

      <Box mb={3}>
        <FormLabelWithError label={memoLabel} errorMessage={errorsByField['memo']} />
        <Textarea rows={4} disabled={formDisabled} name="memo" value={memo} onChange={e => setMemo(e.target.value)} />
      </Box>
  </Box>

  <Flex bg='muted' p={3} justifyContent='flex-end'
        alignItems='center' >
    {!forceContactSales &&
    <Button mr={3} sx={{width: '200px'}}
            disabled={formDisabled}
            onClick={props.openDraftCart}
            variant='secondaryOutline'>Back</Button>}
    <Button disabled={formDisabled} onClick={submitForm} sx={{width: '200px'}} >Submit</Button>
  </Flex>
      </>
    );
      }

  return (
    <Box>
      <Flex variant='primarybg' px={4} py={3} mb={2} alignItems='center' >
        <Heading>{Settings.contactSalesString}</Heading>
        <Box mx='auto' />
        <Link color='white' fontSize={4} onClick={props.toggleModal}>✕</Link>
      </Flex>

      {formBlock}
    </Box>
  )
}

export default ContactSalesScreen;
