import React, { useState, useEffect, useRef } from 'react'

import ReactDOM from 'react-dom'
import { useTheme } from 'emotion-theming'

import { Flex, Box, Button, Text, Link, Heading } from 'rebass'
import { Label, Input, Textarea } from '@rebass/forms'

import Modal, { ModalProvider, BaseModalBackground } from "styled-react-modal";

import styled from '@emotion/styled'

const StyledModal = Modal.styled`
  min-width: 650px;
  margin-top: 2rem;
  background-color: white;
`;

const SpecialModalBackground = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: scroll;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: flex-start;
`

const SubHeading = props => (
  <Heading
    {...props}
    fontSize={3}
    fontWeight={600}
    color='secondary' />
)

const TextFilter = props => {
  const [matchInput, setMatchInput] = useState("");
  const [matchEngaged, setMatchEngaged] = useState(false);
  const parseMatchInput = (inputText) => {
    return inputText.split("\n");
  }


  const theme = useTheme()

  let fieldName = props.filter.field_display_name;

  let valueIsSelected = true;
  let currentValue = props.currentValue;

  let fontColor = theme.colors.secondaryLightText;
  if (currentValue === undefined || currentValue == null || currentValue == "" ||
      _.isEmpty(currentValue)) {
    currentValue = "";
    valueIsSelected = false;
  }
  
  const [textInput, setTextInput] = useState(currentValue);

  useEffect(() => {
    let initialMatchInput = "";
    if (props.currentValue && Array.isArray(props.currentValue) && !_.isEmpty(props.currentValue)
        && props.currentValue.length > 1) {
      setMatchInput(props.currentValue.join("\n"));
    }

    if (props.currentValue == null) {
      setTextInput("");
      setMatchEngaged(false);
    } else {
      setTextInput(props.currentValue);
    }
  }, [props.currentValue])

  const setMatch = () => {
    if (matchInput === "") {
      props.setFilterValue(null)
      setMatchEngaged(false);
    } else {
      const parsedInputs = parseMatchInput(matchInput);
      setMatchEngaged(true);
      props.setFilterValue(parsedInputs);
    }
    closeMatcher();
  }

  const [isMatcherOpen, setMatcherState] = useState(false);
  const openMatcher = () => {
    setMatcherState(true);
  }

  const closeMatcher = () => {
    setMatcherState(false);
  }

  const clearInput = () => {
    setMatchInput("");
    setMatchEngaged(false);
    props.setFilterValue(null);
  }

  let prompt = 'Enter a value';
  let inputDisabled = false;

  if (props.isSearching) {
    inputDisabled = true;
  }

  let currentValueAsOption;
  if (currentValue == null) {
    currentValueAsOption = null
  } else {
    if (props.filter.is_multi) {
      currentValueAsOption = currentValue.map(e => ({ value: e, label: e }));
    } else {
      currentValueAsOption = { value: currentValue[0], label: currentValue[0] }
    }
  }

  if (props.filter.match_enabled && matchEngaged) {
    inputDisabled = true;
    const currentValueSet = new Set(props.currentValue);
    prompt = `${currentValueSet.length} via match`;
  }

  return  <Box sx={{position: 'relative'}} mb={4} {...props}>
            <ModalProvider backgroundComponent={SpecialModalBackground}>

              <Flex
                sx={{minHeight: '27px'}}
                mb={2}>
                <Box><Label sx={{textTransform: 'capitalize', height: '100%'}} m={0}>{fieldName}</Label></Box>
                <Box mx='auto' />
                <Box>
                   {valueIsSelected && <Button onClick={clearInput} variant='labelAction'>Clear</Button>}
                   {props.filter.match_enabled && <Button ml={2} onClick={openMatcher} variant='labelAction'>Match</Button>}
                </Box>
              </Flex>

              <Input color='secondaryLightTextMuted'
                     onChange={e => setTextInput(e.target.value)}
                     onBlur={e => props.setFilterValue(textInput)}
                     onKeyDown = {e => { if (e.key == 'Enter') { e.target.blur(); }}}
                     value={textInput}
                     disabled={inputDisabled}
                     py={'8px'}
                     px={'16px'}
                     bg='secondaryPlaceholder'
                     placeholder={prompt}
                     sx={{borderRadius: '4px',
                          minHeight: '54px',
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          color: theme.colors.secondaryLightTextMuted,
                          borderColor: theme.colors.secondaryPlaceholder,
                          '&:focus': {
                            borderColor: theme.colors.accent,
                            color: theme.colors.secondaryLightTextMuted,
                            boxShadow: 'none',
                          },
                          '&:disabled': {
                            backgroundColor: theme.colors.secondaryPlaceholder
                          },
                          '::placeholder': {
                            color: theme.colors.secondaryLightTextMuted,
                            opacity: '100'
                          }
                         }}>
              </Input>
               <StyledModal isOpen={isMatcherOpen}
                            onEscapeKeydown={closeMatcher}>
                 <Box color='darkText'>
                   <Flex variant='primarybg' px={4} py={3} alignItems='center' >
                     <Heading sx={{textTransform: 'capitalize'}}>{fieldName}</Heading>
                     <Box mx='auto' />
                     <Link color='white' fontSize={4} onClick={closeMatcher}>✕</Link>
                   </Flex>
                   <Box mb={4} pt={4} px={4}>
                     <SubHeading mb={3}>Paste in a column of values to match</SubHeading>
                     <Textarea value={matchInput} onChange={e => setMatchInput(e.target.value)} rows={20} />
                     <Text my={2}><em>Put each input on a separate line. Matches are case sensitive.</em></Text>
                   </Box>
                   <Flex bg='muted' p={3} justifyContent='flex-end'
                         alignItems='center' >
                     <Button mr={3} sx={{width: '200px'}}
                             onClick={closeMatcher}
                             variant='secondaryOutline'>Cancel</Button>
                     <Button onClick={setMatch} sx={{width: '200px'}} >Apply</Button>
                   </Flex>
                 </Box>
               </StyledModal>
            </ModalProvider>
          </Box>
}

export default TextFilter;
