import styled from '@emotion/styled';
import Color from 'color';

const StyledTable = styled.table`
  {
    width: 100%;
    border: 1px solid rgba(34, 36, 38, 0.1);
    border-radius: 4px;
    background-color: white;
    border-collapse:collapse;
    border-spacing:0;
    tbody tr td {
      border: 1px solid rgba(34, 36, 38, 0.1);
      padding: 10px 20px;
    }
    thead tr {
     background-color: ${props => Color(props.theme.colors.secondary).alpha(0.05).string()};
    }
    thead tr th {
     text-align: left;
     padding: 10px 20px;
     color: ${props => props.theme.colors.secondary};
     font-weight: bold;
     border: 1px solid rgba(34, 36, 38, 0.1);
    }
  }
`;

export default StyledTable;
