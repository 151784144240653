import React from 'react';

import { Box, Flex } from 'rebass'

import LogoBlock from './logo_block.jsx'

const Sidebar = (props) => {
  return(
    <Box
      m={0}
      p={0}
      minHeight={'100%'}
      width={1/4}
      sx={{'boxShadow': '11px 0px 11px -3px rgba(8, 24, 28, 0.16)'}}
      variant={ props.transparentBg ? 'blue' :  'darkbg' }
      {...props} >
      <Flex px={4} py={4} flexDirection={'column'} >
        <Box mx='auto' mt={3} mb={4}><LogoBlock logoUrl={props.shopConfig.logo_url} /></Box>
        {props.children}
      </Flex>
    </Box>
  );
}

export default Sidebar;
