import React, { useState } from 'react';

import { Button, Text, Flex, Box, Link } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import { curFmt } from '../util.js'

import { StripeCardForm } from './stripe_card_form.jsx'

export const StripeCheckoutForm = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);

  const handleSubmit = (stripe, cardElement, name, postal) => {
    stripe.confirmCardPayment(
      props.clientSecret,
      { payment_method: {
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          address: {
            postal_code: postal,
          },
        }
      }}).then(result => {
        if (result.error) {
          setErrorMessage(result.error.message);
          setFormDisabled(false);
        } else {
          props.onSuccessfulPayment(result.paymentIntent)
        }
      });
  }

  return <StripeCardForm onFormSubmitted={handleSubmit}
                         formDisabled={formDisabled}
                         setFormDisabled={setFormDisabled}
                         errorMessage={errorMessage}
                         stripePromise={props.stripePromise}
                         cancel={props.cancel}
                         total={props.total}
                         />
}
