import React, { useState, useEffect } from 'react';

import { useHistory, Link as RouterLink } from 'react-router-dom'

import { Heading, Button, Box, Flex, Link, Text } from 'rebass';
import { Label, Input } from '@rebass/forms';
import FormLabelWithError from './components/form_label_with_error.jsx'

import AlertBox from './components/alert_box.jsx';
import axios from './axios_config.jsx';
import { TabNav, TabLink } from './components/tab_nav.jsx';
import AccountLayout from './account_layout.jsx'

import { getUser, updateUser } from './models/user.js';
import { updateBuyer, updateBuyerPaymentMethod } from './models/buyer.js';

import SubscriptionsPanel from './subscriptions_panel.jsx';
import { StripeCardForm } from './components/stripe_card_form.jsx'

import {loadStripe} from '@stripe/stripe-js';

import { Settings } from './settings.js'

let stripePk;
let stripePromise;

if (!Settings.martingale) {
  stripePk = document.querySelector('meta[name="stripe_publishable_key"]').content;
  stripePromise = loadStripe(stripePk);
}

const CompanyProfileForm = (props) => {
  const [errorsByField, setErrorsByField] = useState({});
  const [formDisabled, setFormDisabled] = useState(false);

  let initialBuyerName;
  if (props.buyer) {
    initialBuyerName = props.buyer.name;
  }

  const [name, setName] = useState(initialBuyerName);

  const submit = (e) => {
    console.log('submit')
    e.preventDefault();
    e.stopPropagation();
    setFormDisabled(true);

    updateBuyer({name})
      .then(res=>{
        setFormDisabled(false);
        props.setBuyer(res.data);
        props.afterSave();
      })
      .catch(error => {
        setFormDisabled(false);
        setErrorsByField(error.response.data['errors']);
      });
  }

  return (
    <Box mb={4}>
      <form onSubmit={submit}>
        <Box mb={3}>
          <FormLabelWithError label='Name' for="name" errorMessage={errorsByField['name']} />
          <Input disabled={formDisabled} value={name} onChange={e => setName(e.target.value)} name='name' type='text' />
        </Box>

        <Flex>
          <Button type="submit" variant="buttons.primary" onClick={submit} mr={2} sx={{width: '175px'}}>Save</Button>
          <Button type="cancel" variant="outline" onClick={props.cancelEditing}>Cancel</Button>
        </Flex>
      </form>
    </Box>
  )
}

const CreditCardForm = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);

  const handleSubmit = (stripe, cardElement, name, postal) => {
    stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        address: {
          postal_code: postal,
        },
      },
    })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error.message);
          setFormDisabled(false);
        } else {
          updateBuyerPaymentMethod(result.paymentMethod.id)
            .then(e => props.afterSave())
            .catch(error => { console.log(error) })
        }
      });
  };

  return <Box variant='card'>
           <StripeCardForm onFormSubmitted={handleSubmit}
                           formDisabled={formDisabled}
                           setFormDisabled={setFormDisabled}
                           errorMessage={errorMessage}
                           stripePromise={stripePromise}
                           ctaText='Update card'
                           cancel={props.cancelEditing}
                           total={props.total}
           />
         </Box>
}

const CompanyPanel = (props) => {
  const [editing, setEditing] = useState(false);
  const [editingCreditCard, setEditingCreditCard] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const onSave = (e) => {
    setEditing(false);
    setEditingCreditCard(false);
    setShowSuccess(true);
  }

  const openEditForm = (e) => {
    setEditing(true);
    setShowSuccess(false);
  }

  const openEditCreditCardForm = (e) => {
    setEditingCreditCard(true);
    setShowSuccess(false);
  }

  if (props.buyer) {
    if (editing) {
      return (<Box mb={4}>
                <Heading fontSize={3} mt={3} mb={2}>Edit Company</Heading>
                <CompanyProfileForm setBuyer={props.setBuyer} buyer={props.buyer} afterSave={onSave} cancelEditing={e => setEditing(false)} />
              </Box>);
    } else if (editingCreditCard) {
      return (<Box mb={4}>
                <Heading fontSize={3} mt={3} mb={2}>Change card</Heading>
                <CreditCardForm buyer={props.buyer} afterSave={onSave} cancelEditing={e => setEditingCreditCard(false)} />
              </Box>);
    } else {
      return (<Box mb={4}>
                <Heading fontSize={3} mt={3} mb={2}>Company</Heading>
                {showSuccess && <AlertBox mb={3} p={2} >Company updated</AlertBox>}
                <Text mb={3}>{props.buyer && props.buyer.name}</Text>
                <Button onClick={openEditForm} variant='secondaryOutline'>Edit company</Button>
                { !Settings.martingale &&
                <Button onClick={openEditCreditCardForm} variant='secondaryOutline' ml={2}>Change credit card</Button> }
              </Box>);
    }
  } else {
    return null;
  }
}

const UserProfileForm = (props) => {
  const [errorsByField, setErrorsByField] = useState({});
  const [unknownError, setUnknownError] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [first_name, setFirstName] = useState(props.userProfile.first_name);
  const [last_name, setLastName] = useState(props.userProfile.last_name);
  const [email, setEmail] = useState(props.userProfile.email);

  const submit = (e) => {
    e.preventDefault();
    setFormDisabled(true);
    updateUser({email, first_name, last_name})
      .then(res=>{
        setFormDisabled(false);
        props.setUserProfile(res.data);
        props.afterSave();
      })
      .catch(error => {
        setFormDisabled(false);
        if (error.response.data) {
          setErrorsByField(error.response.data['errors']);
        } else {
          setUnknownError(true);
        }
      });
  }

  return (
    <Box mb={4}>
      { unknownError && <Text mb="2" color='red'>An unknown error occured</Text> }
      <form onSubmit={submit}>
        <Box mb={3}>
          <FormLabelWithError label='First Name' errorMessage={errorsByField['first_name']} />
          <Input disabled={formDisabled} value={first_name} onChange={e => setFirstName(e.target.value)} name='first_name' type='text' />
        </Box>

        <Box mb={3}>
          <FormLabelWithError label='Last Name' errorMessage={errorsByField['last_name']} />
          <Input disabled={formDisabled} value={last_name} onChange={e => setLastName(e.target.value)} name='last_name' type='text' />
        </Box>

        <Box mb={3}>
          <FormLabelWithError label='Email' errorMessage={errorsByField['email']} />
          <Input disabled={formDisabled} value={email} onChange={e => setEmail(e.target.value)} name='email' type='text' />
        </Box>

        <Flex>
          <Input type="submit" variant="buttons.primary" mr={2} sx={{width: '175px'}} value="Save" />
          <Button type="cancel" variant="outline" onClick={props.cancelEditing}>Cancel</Button>
        </Flex>
      </form>
    </Box>
  )
}


export default function ProfileScreen(props) {
  const [userProfile, setUserProfile] = useState(null);
  const [buyer, setBuyer] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [editingProfile, setEditingProfile] = useState(false);
  const [error, setError] = useState(null);
  let history = useHistory();
  let setUserSession = props.setUserSession;

  useEffect(() => {
    getUser()
      .then(res => {
        if (!_.isEmpty(res.data.buyers)) {
          setBuyer(res.data.buyers[0]);
        }
        setUserProfile(res.data);
      })
      .catch(error => {
        setError(error.response.data['error']);
      })
  }, [])

  const onSave = (e) => {
    setEditingProfile(false);
    setShowSuccess(true);
  }

  const openForm = (e) => {
    setEditingProfile(true);
    setShowSuccess(false);
  }

  const signOut = (props) => {
    axios
      .delete('/users/sign_out')
      .then(res =>{
        setUserSession(null);
        window.location.reload();
      })
      .catch(error => {
        console.log(error)
        console.log("could not log out");
      })
  }

  let profileBox;
  if (userProfile) {
    let openFormAction = null;

    if (!userProfile.managed_through_sso) {
      <Button onClick={openForm} variant='secondaryOutline'>Edit profile</Button>;
    }

    let userProfileSection =
      <Box>
    <Heading fontSize={3} mb={2}>User profile</Heading>
    {showSuccess && <AlertBox mb={3} p={2} >Profile updated</AlertBox>}
        <Flex mb={2}><Text mr={1}>{userProfile.first_name}</Text> <Text>{userProfile.last_name}</Text></Flex>

        <Text mb={3}>{userProfile.email}</Text>
        {openFormAction}
      </Box>

    if (editingProfile) {
      userProfileSection =
        <UserProfileForm afterSave={onSave}
                         cancelEditing={e => setEditingProfile(false)}
                         setUserProfile={setUserProfile}
                         userProfile={userProfile} />
    }

    let companyPanels;
    if (!Settings.martingale) { // charge codes
      companyPanels = <CompanyPanel setBuyer={setBuyer} buyer={buyer} />
    }

    profileBox = <Box mb={4}>
      <Box mb={4}>{userProfileSection}</Box>
      {companyPanels}
      <SubscriptionsPanel vendorId={props.vendorId} />
    </Box>
  }

  return <AccountLayout {...props} activeTab='profile'>
           <Flex justifyContent='space-between'>
             {profileBox}

             <Box>
               <Button onClick={signOut}>Log out</Button>
             </Box>
           </Flex>
         </AccountLayout>
}
