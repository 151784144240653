import React from 'react';

import { Text, Box, Flex } from 'rebass'

const AlertBox = (props) => {
    return(<Box
             mb={4}
             p={3}
             variant='accentbg'
             sx = {{
               textAlign: 'center'
             }}
             {...props}
           >
             {props.children}
           </Box>
          );
}

export default AlertBox;
