import React from 'react';

import NoSidebarHeader from './no_sidebar_header.jsx';

import { Box } from 'rebass'

export default function NoSidebarLayout(props) {
  return <Box sx={{minHeight: '100vh'}}>
           <NoSidebarHeader {...props} />

           <Box mx={7}>
             {props.children}
           </Box>
         </Box>
}
