import React, { useState, useEffect } from 'react';

import { useHistory, Link as RouterLink } from 'react-router-dom'

import { createChargeCodePayment } from '../models/charge_code_payments.js'

import FormLabelWithError from './form_label_with_error.jsx'

import { Button, Text, Flex, Box } from 'rebass'

import { Label, Input, Checkbox } from '@rebass/forms'

const InputWithLabel = (props) => {
    return(<Box sx= {{ letterSpacing: '0.025em' }}
                my={4}>
             <FormLabelWithError label={props.fieldLabel} errorMessage={props.errorsByField[props.field]} />
             <Input sx={{fontSize: '18px', color: '#424770'}} mt={'10px'}
                    disabled={props.disabled} autoComplete='off' name={props.field} type='text' value={props.fieldValue} onChange={e => props.setVal(e.target.value)} />
           </Box>);
  }

const CheckboxWithLabel = (props) => {
  let toggleFieldValue = (e) => {
    props.setVal(e.target.checked);
  }
  let errorMessageBox;
  const errorMessage = props.errorsByField && props.errorsByField[props.field];
  if (errorMessage && errorMessage.length > 0) {
    errorMessageBox = <Text color='red'>{errorMessage[0].toLowerCase()}</Text>
  }

  return(<Box sx={{ letterSpacing: '0.025em' }}
              mb={3}>
    <Flex mb={2}>
      <Label sx={{width: 'auto'}}>{props.fieldTitle}</Label>
      <Box mx='auto'/>
      {errorMessageBox}
    </Flex>

    <Label>
      <Checkbox
        disabled={props.disabled}
        name={props.field}
        checked={props.fieldValue}
        onChange={toggleFieldValue}
        mr={3}
      />
      {props.fieldLabel}
    </Label>
  </Box>);

}

export const ChargeCodeForm = (props) => {
  const [charge_code, setChargeCode] = useState("");
  const [project_name, setProjectName] = useState("");
  const [notes, setNotes] = useState("");
  const [accepted_charge_code_terms, setAcceptedChargeCodeTerms] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [errorsByField, setErrorsByField] = useState({});
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  let history = useHistory();

  const submitForm = (e) => {
    e.preventDefault();
    setFormDisabled(true);
    setErrorsByField({});
    createChargeCodePayment({
      charge_code,
      project_name,
      notes,
      accepted_charge_code_terms,
      cart_id: props.cart.id
    })
      .then(res => {
        history.replace({ pathname: '/account/downloads',
                          state: { flash: 'Thank you for your order.' }});

      }).catch(error => {
        console.log(error)
        setFormDisabled(false)
        if (error.response) {
          setErrorsByField(error.response.data['errors']);
        }
      })
  }

  let formBlock;
  if (submissionSuccessful) {
    formBlock = <Text textAlign='center' fontSize={3} p={3}>
                  Your order has been submitted.
                </Text>
  } else {
    formBlock = (
      <Box as='form' my={4} onSubmit={submitForm} >
        <Box my={3}>
          <InputWithLabel disabled={formDisabled} field='charge_code' fieldLabel='Charge code' errorsByField={errorsByField} setVal={setChargeCode} fieldValue={charge_code} />
          <InputWithLabel disabled={formDisabled} field='project_name' fieldLabel='Project name (optional)' errorsByField={errorsByField} setVal={setProjectName} fieldValue={project_name} />
          <InputWithLabel disabled={formDisabled} field='notes' fieldLabel='Notes (optional)' errorsByField={errorsByField} setVal={setNotes} fieldValue={notes} />

          <CheckboxWithLabel disabled={formDisabled} field='accepted_charge_code_terms' fieldLabel='I confirm that I have approval to license this data using the provided charge code' errorsByField={errorsByField} setVal={setAcceptedChargeCodeTerms} fieldValue={accepted_charge_code_terms} fieldTitle='License confirmation' />
        </Box>

        <Flex mt={4}>
          <Button onClick={props.cancel}
                  disabled={formDisabled}
                  type="button"
                  variant='outline'
                  to="/"
                  as={RouterLink}>
            Cancel
          </Button>

          <Box mx='auto' />

          <Button type="submit" variant='primary' disabled={formDisabled} onClick={submitForm} sx={{width: '200px'}} >Submit</Button>
        </Flex>
      </Box>
    );
  }
  return <Box>{formBlock}</Box>;
}
