import React from 'react';

import { useLocation } from 'react-router-dom'
import { Text, Box, Flex } from 'rebass'

const FlashAlert = (props) => {
  let location = useLocation();
  if (location && location.state && location.state.flash) {
    return(<Box
             mb={3}
             p={3}
             variant='accentbg'
             sx = {{
               textAlign: 'center',
               position: 'relative',
               'zIndex': 2
             }}
             {...props}
           >
             <Text>{location.state.flash}</Text>
             </Box>

          );
  } else {
    return(null);
  }
}

export default FlashAlert;
