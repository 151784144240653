import React, { useState, useEffect } from 'react';

import { Redirect, useHistory, Link as RouterLink } from 'react-router-dom'

import NoSidebarLayout from './no_sidebar_layout.jsx'
import { createStripeSubscriptionFromCart } from './models/cart.jsx'

import { Li, Button, Text, Flex, Box, Link, Heading } from 'rebass'

import FlashAlert from './components/flash_alert.jsx'
import AlertBox from './components/alert_box.jsx'
import Loading from './components/loading.jsx'

import { StripeSubscriptionForm } from './components/stripe_subscription_form.jsx'
import { InvoiceRequestForm } from './components/invoice_request_form.jsx'
import { ChargeCodeForm } from './components/charge_code_form.jsx'

import { curFmt } from './util.js'
import {loadStripe} from '@stripe/stripe-js';
import * as _ from 'lodash'
import { Settings } from './settings.js';

const NewSubscriptionScreen = props => {
  let initialPaymentMethod = "card";
  if (Settings.martingale) {
    initialPaymentMethod = "charge-code";
  }

  const [paymentMethod, setPaymentMethod] = useState(initialPaymentMethod);
  const [errors, setErrors] = useState(null);
  let history = useHistory();
  let stripePromise = loadStripe(props.stripePk);
  const cancel = () => {
    props.setCart({})
    history.push({ pathname: '/',
                   state: { flash: 'Your order has been canceled.' }});
  }

  const setPaymentMethodInvoice = () => setPaymentMethod('invoice');
  const setPaymentMethodCard = () => setPaymentMethod('card');

  const createSubscription = (paymentMethodId) => {
    createStripeSubscriptionFromCart(props.cart, paymentMethodId)
      .then (res => {
        history.replace({ pathname: '/account/downloads',
                          state: { flash: 'Your subscription is active.' }});
      })
      .catch (error => {
        console.log("Could not subscribe");
      });
  }

  const displayError = (x) => {
    setErrors(x.error);
  }

  const total = props.cart.total;
  const interval = 'month';

  let paymentBox = <Flex justifyContent='center'><Loading/></Flex>;

  let errorBox;
  if (errors) {
    errorBox = <Text sx={{color: '#9e2146'}}>{errors.message}</Text>
  }

  if (paymentMethod == 'invoice') {
    paymentBox = <InvoiceRequestForm
                   cart={props.cart}
                   total={total}
                   cancel={cancel} />
  } else if (paymentMethod == 'charge-code') {
    paymentBox = <ChargeCodeForm
                   cart={props.cart}
                   total={total}
                   cancel={cancel} />
  } else {
      paymentBox = <StripeSubscriptionForm
                     stripePromise={stripePromise}
                     interval={interval}
                     total={total}
                     createSubscription={createSubscription}
                     displayError={displayError}
                     ctaText={`Subscribe for ${curFmt.format(total)}/month`}
                     cancel={cancel} />
    }

  if (_.isEmpty(props.cart)) {
    return <Redirect to="/" />
  } else {
    return <NoSidebarLayout {...props}>
             <Box mt={6}
                  pb={6}
                  mx='auto'
                  sx={{maxWidth: '500px'}}>

               <Flex
                 flexDirection='column'
                 py={4}
                 px={5}
                 sx={{borderRadius: "4px",
                      boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)'}}
                 bg='white'>
                 <FlashAlert />
                 <AlertBox>
                   Enter your payment information to subscribe.
                 </AlertBox>
                 {errorBox}
                 {paymentBox}

    </Flex>

    { !Settings.martingale &&
      <Flex my={4} mx={3}>
        { paymentMethod == 'card' ? <Link onClick={setPaymentMethodInvoice}>Want to pay by invoice?</Link>
          :  <Link onClick={setPaymentMethodCard}>Pay by credit card</Link> }
      </Flex> }
             </Box>
           </NoSidebarLayout>
    }
  }

export default NewSubscriptionScreen;
