import React, { useEffect, useState } from 'react';

import { Redirect, Link as RouterLink } from 'react-router-dom';
import { useTheme } from 'emotion-theming'

import { Image, Button, Heading, Link, Text, Flex, Box } from 'rebass';
import Sidebar from './components/sidebar.jsx';
import Header from './components/header.jsx';
import FlashAlert from './components/flash_alert.jsx';
import styled from '@emotion/styled';

const VerticalProductCards = (props) => {
  let productPanes;
  if (!_.isEmpty(props.dataProducts)) {
    productPanes = props.dataProducts.map((dp, i) => {

      return (
        <Flex variant='packageCard' flexDirection='column' justifyContent='space-between' alignItems='center' mb={5} p={3} bg='white'
              sx={{ textAlign: 'center', borderRadius: '4px',
                    borderTopColor: 'accent',
                    borderTopStyle: 'solid',
                    borderTopWidth: '6px',
                    boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)',
                    maxWidth: ['100%', '450px'],
                    width: ['46%'] }}
              key={dp.id}>

          <Flex flexGrow={1} flexDirection='column' justifyContent='space-between' alignItems='center' p={3} >
            <Heading mb={2} fontSize={4}>{dp.name}</Heading>
            <Box my={3}>
              <img src={dp.placeholder_image_url} />
            </Box>

            <Text
              flexGrow={1}
              sx={{maxHeight: '200px', overflow: 'auto'}}
              mb={3}
              fontSize={2}
              color='gray'><div dangerouslySetInnerHTML={{__html: dp.description_formatted_sanitized}} /></Text>

            <Button as={RouterLink}
                  variant='blackOutline'
                  to={`/products/${dp.id}`}
                  sx={{width: '275px'}}>View Dataset</Button>
          </Flex>
        </Flex>
      )
    });


    return (<Flex sx={{zIndex: 2, position: 'relative'}} justifyContent='space-evenly' flexWrap='wrap'>
              {productPanes}
            </Flex>);

  } else {
    return null
  }
}

const HeroImage = (props) => {
  return(
    <Image sx={{ overflow: 'hidden', position: 'fixed', zIndex: 1,
                 top: 0, left: 0, height: '100vh', width: '100%',
                 objectFit: 'cover'}} px={0} src={props.imageUrl} />
  )
}

export default function HomeScreen(props) {
  const dataProducts = props.homeScreen.data_products;

  if (dataProducts.length == 1) {
    return(<Redirect to={{ pathname: `/products/${dataProducts[0].id}`, }} />);
  }

  let descriptionBox;

  if (props.homeScreen.description) {
    descriptionBox =
      <Box mb={5}
           mx='auto'
           sx={{position: 'relative',
                textAlign: 'center',
                width: ['100%', '75%'],
                zIndex: 2 }}
           px={[2,2,6]} py={4}>
        <Text mx='auto' sx={{fontSize: '20px', lineHeight: '24px'}}>{props.homeScreen.description}</Text>
      </Box>
  }

  const theme = useTheme()
  let boxWidth = '600px';

  let headerButtonVariant = 'outline';
  let titleColor = props.siteTheme.colors.darkText;
  if (props.homeScreen.hero_image_url) {
    headerButtonVariant = 'darkbg.filled';
    titleColor = 'textOnSecondary';
  }

  return <Box sx={{minHeight: '100vh'}}>
           <Flex minWidth='1400px' minHeight={'100vh'}>
             <Sidebar sx={{position: 'relative', zIndex: '5'}} shopConfig={props.shopConfig}>
               <Text mt='5' mx='auto' sx={{fontSize: '17px', lineHeight: '26px', textAlign: 'center'}}>
                 {props.homeScreen.description}
               </Text>               
             </Sidebar>
             <Box
               bg='#FAFAFA'
               minWidth={'900px'}
               width={3/4} >
               {props.homeScreen.hero_image_url && <HeroImage imageUrl={props.homeScreen.hero_image_url} />}

               <Header variant={headerButtonVariant} sx={{position: 'relative', zIndex: 5}} {...props} />


               <Box px={4} pt={4}  sx={{position: 'relative', zIndex: 5}}>
                 <FlashAlert my={4} mx='auto' width='50%' />

                 <Box mx='auto' sx={{position: 'relative', zIndex: 5}} >
                   <Flex mb={5} flexDirection='column' justifyContent='center' alignItems='center' className="mainContent">
                     <Heading color={titleColor}  mx='auto' mb={5} fontSize={5}>{props.homeScreen.title || 'Data Shop'}</Heading>
                   </Flex>
                   <VerticalProductCards homeScreen={props.homeScreen} dataProducts={dataProducts} />

                 </Box>
               </Box>
             </Box>
           </Flex>
         </Box>
}
