import React, { useState } from 'react';

import { Redirect, useHistory, useLocation, Link as RouterLink } from 'react-router-dom'

import styled from '@emotion/styled';
import { Button, Link, Flex, Box, Text, Heading } from 'rebass';
import { Label, Input } from '@rebass/forms';

import * as _ from 'lodash';
import axios from './axios_config.jsx';
import NoSidebarHeader from './no_sidebar_header.jsx';
import FlashAlert from './components/flash_alert.jsx'
import { RegistrationForm } from './registration_screen.jsx';
import { acceptCustomerInvite } from './models/customer_invite.js';

const CustomerInviteForm = props => {
  let [formSubmitting, setFormSubmitting] = useState(false);
  let history = useHistory();
  let location = useLocation();

  const acceptInvite = () => {
    setFormSubmitting(true)
    acceptCustomerInvite({ id: props.customerInviteId,
                           token: props.customerInviteToken })
      .then(res => {
        if (res.data.payment_url) {
          history.push({ pathname: res.data.payment_url });
        } else {
          history.push({ pathname: '/account/downloads',
                         state: { flash: 'Invite accepted. Access your data here.' }});
        }
      })
      .catch(error => {
        setFormSubmitting(false)
        if (error.response && error.response.data) {
          alert(error.response.data.errors.join("\n"));
        } else {
          console.log(error);
        }
      })
  }

  let formPanel;
  if (props.userSession && props.userSession.role == 'shop_admin') {
    formPanel = <Redirect to={{ pathname: "/", state: { flash: "You are already signed in as a shop admin." }}} />;
  } else if (props.userSession && props.userSession.role == 'shopper') {
    formPanel = <Box>
                  <Heading my={4}>Access your data</Heading>
                  <Text mb={4}>You are already signed in. Click the button below to access this dataset.</Text>
                  <Button disabled={formSubmitting} onClick={acceptInvite}>Get dataset</Button>
                </Box>
  } else {
    formPanel = <>
                  <Heading my={4}>Create an account to access your data</Heading>
                  <RegistrationForm setFormSubmitting={setFormSubmitting} afterRegister={acceptInvite} {...props} />
                </>
  }

  return(
    <Flex
      flexDirection='column'
      mt={3}
      mx='auto'
      sx={{width: ['100%', '80%', '60%'],
           maxWidth: '650px'}}>
      <Flex
        flexDirection='column'
        py={4}
        px={5}
        sx={{borderRadius: "4px",
             boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)'}}
        bg='white'>
        <FlashAlert />
        {formPanel}
      </Flex>
    </Flex>);
}

export default function CustomerInviteScreen(props) {
  const pageUrl = location.href;
  const matches = pageUrl.match(/invite\/(.*)\?token=(.*)/);
  const inviteId = matches[1];
  const inviteToken = matches[2];
  return <Box sx={{minHeight: '100vh'}}>
           <NoSidebarHeader {...props} />
           <CustomerInviteForm customerInviteId={inviteId}
                               customerInviteToken={inviteToken}
                               {...props} />
         </Box>
}
