import React from 'react'
import { Link, Button } from 'rebass'
import { useTheme } from 'emotion-theming'

export const NavLink = props => {
  const theme = useTheme();
  return (<Link
            mx={3}
            variant='subheading'
            sx = {{fontSize: 2, color: theme.colors.darkText, textDecoration: 'none'}}
            {...props}
          />
         )}

export const NavButton = props => {
  return (<Button
            mr={3}
            ml={4}
            variant='outline'
            sx = {{fontSize: 3, textDecoration: 'none', minWidth: '150px'}}
            {...props}
          />)}
