import React from 'react'
import Popup from 'reactjs-popup'
import styled from '@emotion/styled'
import { InformationCircle } from '@styled-icons/ionicons-outline';


const StyledPopup = styled(Popup)`
  &-arrow {
    color: rgb(255, 255, 255);
  }
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
    background: transparent;
  }
  &-content {
    margin: auto;
    background: rgb(255, 255, 255);
    font-family: ${props => props.theme.fonts.body};
    padding: 20px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
`

export const Tooltip = (props) => (
  <StyledPopup
    trigger={open => (
      <InformationCircle size={props.size || 18} />
    )}
    {...props}
    on={['hover', 'focus']}
    position={['right', 'left']}
    keepTooltipInside={true}
    closeOnDocumentClick
  >
    <span>{props.children}</span>
  </StyledPopup>
);
