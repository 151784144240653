import axios from '../axios_config.jsx';

export const retrieveOrders = (attrs) => {
  return axios.get('/api/shop/orders', { params: { vendor_id: attrs.vendorId }} );
}

export const retrieveOrder = (orderId) => {
  return axios.get('/api/shop/orders/${orderId}', { params: { vendor_id: attrs.vendorId }} );
}

export const createOrderPaymentIntent = orderId => {
  return axios.post(`/api/shop/orders/${orderId}/payment_intent`)}

export const markOrderPaid = orderId => {
  return axios.post(`/api/shop/orders/${orderId}/payment_successful`)}
