export const dataProductSearchPath = (dataProductId, filters) => {
  let params = new URLSearchParams();
  _.each(filters, ((x, y) => {
    if (x !== null && x !== undefined) {
      params.set(y, x);
    }
  }));

  return(`/products/${dataProductId}?${params.toString()}`);
};
