import Color from 'color';

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ?
    [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

const luminance = (r, g, b) => {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

const contrast = (rgb1, rgb2) => {
    var lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
    var lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);
    return (brightest + 0.05)
         / (darkest + 0.05);
}

const lightenConditionally = (color) => {
  const lightness = color.lightness();

  if (lightness < 30) {
    return color.lighten(1.4);
  } else if (lightness < 50) {
    return color.lighten(1);
  } else if (lightness < 70) {
    return color.lighten(0.4);
  } else {
    return color.lighten(0.1);
  }
}

const ensureContrast = (color, background, threshold) => {
  let direction = 'darken';

  let newColor = color;
  if (newColor == '#000000') {
    newColor = '#0b0a0a';
    direction = 'lighten';
  }

  let oldColor = newColor;

  while(contrast(hexToRgb(newColor), hexToRgb(background)) <= threshold) {
    if (direction == 'darken') {
      newColor = Color(newColor).darken(0.1).hex();
    } else {
      newColor = Color(newColor).lighten(0.2).hex();
    }

    if (newColor == oldColor) {
      if (direction == 'darken') {
        direction = 'lighten';
      } else {
        return newColor; // we can't make progress
      }
    }

    oldColor = newColor;
  }

  return newColor;
}

export const makeColorMap = (attrs) => {
  const secondary = attrs.secondary
  const primary = attrs.primary
  const accent = attrs.accent

  let textOnSecondary = 'white';
  let secondaryLightText = 'white';
  let sidebarInputText = '#d2d2d2';
  let backgroundOnSecondary = Color(secondary).lighten(0.4).toString();

  let secondaryInspiredTextColor = secondary;

  if (Color(secondary).isLight()) {
    backgroundOnSecondary = '#FAFAFA';

    while(contrast(hexToRgb(backgroundOnSecondary), hexToRgb(secondary)) < 1.1) {
      backgroundOnSecondary = Color(backgroundOnSecondary).darken(0.1).hex();
    }

    textOnSecondary = '#4F4F4F';
    secondaryLightText = '#4F4F4F';

    sidebarInputText = '#7d7d7d';
    while(contrast(hexToRgb(sidebarInputText), hexToRgb(backgroundOnSecondary)) < 4.5) {
      sidebarInputText = Color(sidebarInputText).darken(0.4).hex();
    }

    while(contrast(hexToRgb(secondaryInspiredTextColor), [250, 250, 250]) < 4.5) {
      secondaryInspiredTextColor = Color(secondaryInspiredTextColor).darken(0.4).hex();
      console.log(secondaryInspiredTextColor);
    }
  }

  let primaryInspiredTextColor = primary;
  while(contrast(hexToRgb(primaryInspiredTextColor), [250, 250, 250]) < 4.5) {
    primaryInspiredTextColor = Color(primaryInspiredTextColor).darken(0.4).hex();
  }

  let accentInspiredTextColor = accent;
  while(contrast(hexToRgb(accentInspiredTextColor), [250, 250, 250]) < 4.5) {
    accentInspiredTextColor = Color(accentInspiredTextColor).darken(0.4).hex();
  }

  let primaryCard1 = ensureContrast(primary, '#FFFFFF', 1);
  let primaryCard2 = ensureContrast(primaryCard1, primaryCard1, 1.5);

  let secondaryCard1 = ensureContrast(secondary, '#FFFFFF', 1);
  let secondaryCard2 = ensureContrast(secondaryCard1, secondaryCard1, 1.5);

  let accentCard1 = ensureContrast(accent, '#FFFFFF', 1);
  let accentCard2 = ensureContrast(accentCard1, accentCard1, 1.5);

  let textOnPrimary = '#FFFFFF';
  if (Color(primary).isLight()) {
    textOnPrimary = '#000000';
  }

  return({
    red: '#B00020',
    primary: primary,
    primaryInspiredTextColor: primaryInspiredTextColor,

    secondary: secondary,
    accent: accent,
    accentInspiredTextColor: accentInspiredTextColor,

    textOnSecondary: textOnSecondary,
    textOnPrimary: textOnPrimary,
    darkText: '#4F4F4F',
    gray: '#7d7d7d',
    lightGray: '#e6e6e6',
    muted: Color('#f0f0f0').alpha(0.45).hex(),
    secondaryInspiredTextColor: secondaryInspiredTextColor,
    secondaryPlaceholder: backgroundOnSecondary,
    secondaryLightText: secondaryLightText,
    secondaryLightTextMuted: sidebarInputText,
    mainBackground: '#FAFAFA',

    primaryCard1: primaryCard1,
    primaryCard2: primaryCard2,

    secondaryCard1: secondaryCard1,
    secondaryCard2: secondaryCard2,

    accentCard1: accentCard1,
    accentCard2: accentCard2,
  })
}
