import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import * as _ from 'lodash'

import styled from '@emotion/styled'
import { ThemeProvider, useTheme } from 'emotion-theming'
import preset from '@rebass/preset'

import { Button, Text, Flex, Image, Box, Link, Heading } from 'rebass'

import { Textarea, Label, Select } from '@rebass/forms'

import ReactSelect from 'react-select'

import Color from 'color';

import Cookies from 'js-cookie'

import Price from '../shop/components/price.jsx'
import Loading from '../shop/components/loading.jsx'
import {NavLink, NavButton} from '../shop/nav_elements.jsx'
import { SearchResults } from '../shop/search_results.jsx'
import { makeColorMap } from '../shop/colors.jsx'
import { DraftCart } from '../shop/draft_cart.jsx'
import ContactSalesScreen from '../shop/contact_sales_screen.jsx'
import FlashAlert from '../shop/components/flash_alert.jsx'
import Sidebar from '../shop/components/sidebar.jsx'
import Header from '../shop/components/header.jsx'

import DateRangeFilter from '../shop/components/data_product_filters/date_range_filter.jsx'
import TextFilter from '../shop/components/data_product_filters/text_filter.jsx'
import NumericRangeFilter from '../shop/components/data_product_filters/numeric_range_filter.jsx'

import Modal, { ModalProvider, BaseModalBackground } from "styled-react-modal";

import { Document as DocumentIcon } from '@styled-icons/ionicons-outline';

import { dataProductSearchPath } from './routes.js'

import {
  HashRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
  useParams,
  useLocation,
  useHistory
} from "react-router-dom";

import axios from '../shop/axios_config.jsx';

const StyledModal = Modal.styled`
  min-width: 650px;
  margin-top: 2rem;
  background-color: white;
`;

const SpecialModalBackground = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: scroll;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: flex-start;
`

const DarkBGOption = styled.option`
  background: #0F596D;
`;

const FilterBox = props =>
  <Box
    mb={4}
    {...props}
  />

const SubHeading = props => (
  <Heading
    {...props}
    fontSize={3}
    fontWeight={600}
    color='secondary' />
)

// XXX get out of this file
const FilterWidgetSelect = props => {
  const [matchInput, setMatchInput] = useState("");
  const [matchEngaged, setMatchEngaged] = useState(false);

  const parseMatchInput = (inputText) => {
    return inputText.split("\n");
  }

  useEffect(() => {
    let initialMatchInput = "";
    if (props.currentValue && Array.isArray(props.currentValue) && !_.isEmpty(props.currentValue)
        && props.currentValue.length > 1) {
      setMatchInput(props.currentValue.join("\n"));
    }
  }, [props.currentValue])


  const theme = useTheme()
  const filter_choices = props.facetData;

  let fieldName = props.filter.field_display_name;

  function changeHandler(x) {
    if (x) {
      if (props.filter.is_multi) {
        props.setFilterValue(x.map ( e => e.value ));
      } else {
        if (x.value == '') {
          props.setFilterValue(null);
        } else {
          props.setFilterValue([x.value]);
        }
      }
    } else {
      props.setFilterValue(null);
    }
  }

  let valueIsSelected = true;
  let currentValue = props.currentValue;

  let fontColor = theme.colors.secondaryLightText;
  if (currentValue === undefined || currentValue == null || currentValue == "" ||
      _.isEmpty(currentValue)) {
    currentValue = null;
    valueIsSelected = false;
  }

  if (!valueIsSelected) {
    fontColor = theme.colors.secondaryLightTextMuted;
  }

  const setMatch = () => {
    if (matchInput === "") {
      props.setFilterValue(null)
      setMatchEngaged(false);
    } else {
      const parsedInputs = parseMatchInput(matchInput);
      setMatchEngaged(true);
      props.setFilterValue(parsedInputs);
    }
    closeMatcher();
  }

  const [isMatcherOpen, setMatcherState] = useState(false);
  const openMatcher = () => {
    setMatcherState(true);
  }

  const closeMatcher = () => {
    setMatcherState(false);
  }

  const clearInput = () => {
    setMatchInput("");
    setMatchEngaged(false);
    props.setFilterValue(null);
  }

  let selectDisabled = false;
  let prompt = 'Select an option';

  if (props.isSearching) {
    selectDisabled = true;
  }

  let currentValueAsOption;
  if (currentValue == null) {
    currentValueAsOption = null
  } else {
    if (props.filter.is_multi) {
      currentValueAsOption = currentValue.map(e => ({ value: e, label: e }));
    } else {
      currentValueAsOption = { value: currentValue[0], label: currentValue[0] }
    }
  }

  if (props.filter.match_enabled && matchEngaged) {
    selectDisabled = true;
    const currentValueSet = new Set(props.currentValue);
    let matchedCount = 0;
    _.each(filter_choices, (e) => {
      if (currentValueSet.has(e)) {
        matchedCount += 1;
      }
    });
    prompt = `${matchedCount} via match`;
    currentValueAsOption= [{ label: prompt, value: ""}]
  }

  const selectOptions = filter_choices.map(c => {return { value: c, label: c }});

  const customReactSelectStyles = {
    input: (base, state) => ({
      color: theme.colors.secondaryLightTextMuted,
    }),
    control: (base, state) => ({
      ...base,
      background: theme.colors.secondaryPlaceholder,
      // match with the menu
      borderRadius: '4px',
      // Overwrittes the different states of border
      borderColor: state.isFocused ? theme.colors.accent : theme.colors.secondaryPlaceholder,
      color: theme.colors.secondaryLightTextMuted,
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:focus": {
        // Overwrittes the different states of border
        color: 'white'
      },
      '&:hover': {
        borderColor: state.isFocused ? theme.colors.accent : theme.colors.secondaryPlaceholder,
      },
      padding: '8px'
    }),
    indicatorSeparator: (base, state) => ({ display: 'none' }),
    menu: (base, state) => ({
      ...base,
      marginTop: 4,
    }),
    menuList: (base, state) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0
    }),
    placeholder: (base, state) => ({
      ...base,
      color: theme.colors.secondaryLightTextMuted
    }),
    option: (base, state) => ({
      ...base,
      color: theme.colors.secondaryLightTextMuted,
      backgroundColor: state.isSelected
        ? theme.colors.secondary
        : state.isFocused
        ? theme.colors.secondary
        : theme.colors.secondaryPlaceholder,
      // backgroundColor: state.isFocused ? theme.colors.secondary : theme.colors.secondaryPlaceholder,

      ':active': {
        backgroundColor:
        !state.isDisabled && (state.isSelected ? theme.colors.secondary : theme.colors.secondaryPlaceholder),
      },
    }),
    singleValue: (base, state) => ({
      ...base,
      color: theme.colors.secondaryLightTextMuted,
    }),
    multiValue: (base, state) => ({
      ...base,
      padding: 6,
      marginRight: 4,
    }),
  };


  return <FilterBox {...props}>
             <ModalProvider backgroundComponent={SpecialModalBackground}>

               <Flex mb={2}>
                 <Box><Label sx={{textTransform: 'capitalize', height: '100%'}} m={0}>{fieldName}</Label></Box>
                 <Box mx='auto' />
                 <Box>
                   {valueIsSelected && <Button onClick={clearInput} variant='labelAction'>Clear</Button>}
                   {props.filter.match_enabled && <Button ml={2} onClick={openMatcher} variant='labelAction'>Match</Button>}
                 </Box>
               </Flex>
               <Box color='primary'>
                 <ReactSelect
                   isMulti={props.filter.is_multi ? true : undefined}
                   styles={customReactSelectStyles}
                   isDisabled={selectDisabled}
                   value={currentValueAsOption}
                   onChange={changeHandler}
                   options={selectOptions} />
               </Box>
               <StyledModal isOpen={isMatcherOpen}
                            onEscapeKeydown={closeMatcher}>
                 <Box color='darkText'>
                   <Flex variant='primarybg' px={4} py={3} alignItems='center' >
                     <Heading sx={{textTransform: 'capitalize'}}>{fieldName}</Heading>
                     <Box mx='auto' />
                     <Link color='white' fontSize={4} onClick={closeMatcher}>✕</Link>
                   </Flex>
                   <Box mb={4} pt={4} px={4}>
                     <SubHeading mb={3}>Paste in a column of values to match</SubHeading>
                     <Textarea value={matchInput} onChange={e => setMatchInput(e.target.value)} rows={20} />
                     <Text my={2}><em>Put each input on a separate line. Matches are case sensitive.</em></Text>
                   </Box>
                   <Flex bg='muted' p={3} justifyContent='flex-end'
                         alignItems='center' >
                     <Button mr={3} sx={{width: '200px'}}
                             onClick={closeMatcher}
                             variant='secondaryOutline'>Cancel</Button>
                     <Button onClick={setMatch} sx={{width: '200px'}} >Apply</Button>
                   </Flex>
                 </Box>
               </StyledModal>
             </ModalProvider>
         </FilterBox>
}

// XXX get out of this file
function FilterWidget(props) {
  function setFilter(evt) {
    props.setFilterValue(evt.target.value)
  }

  let currentValue = props.currentValue;
  if (props.currentValue === undefined) {
    currentValue = "";
  }


  if (props.filter.filter_type_name == 'Dropdown' ||
      props.filter.filter_type_name == 'Multi-select Dropdown') {
    return <FilterWidgetSelect {...props}
                               filter={props.filter}
                               currentValue={props.currentValue}
                               setFilterValue={props.setFilterValue} />
  } else if (props.filter.filter_type_name == "Date Range") {
    return <DateRangeFilter {...props}
                            filter={props.filter}
                            currentValue={props.currentValue}
                            setFilterValue={props.setFilterValue}
                            />
  } else if (props.filter.filter_type_name == "Text") {
    return <TextFilter {...props}
                            filter={props.filter}
                            currentValue={props.currentValue}
                            setFilterValue={props.setFilterValue}
                            />
  } else if (props.filter.filter_type_name == "Numeric Range") {
    return <NumericRangeFilter
                            {...props}
                            filter={props.filter}
                            currentValue={props.currentValue}
                            setFilterValue={props.setFilterValue}
    />
  }
  else {
    return <Box {...props}>
             {props.filter.field_display_name}
             <input value={currentValue}
                    type="text"
                    onChange={setFilter} />
           </Box>
  }
}

const FilterSidebar = (props) => {
  const filterSetter = (field) => {
    return function(value) {
      let newFilters = {...props.appliedFilters}
      newFilters[field] = value
      props.setAppliedFilters(newFilters);
    }
  }

  let filters;
  let filterWidgets = null;

  console.log(props.facets);
  if (props.facets == null) {
    return null;
  }
  
  if (props.availableFilters && !_.isEmpty(props.availableFilters)) {
    filterWidgets = props.availableFilters.map((filter) =>
      <FilterWidget
        {...props}
        key={filter.id}
        mb={4}
        facetData={props.facets[filter.field]}
        setFilterValue={filterSetter(filter.field)}
        currentValue={props.appliedFilters[filter.field]}
        filter={filter} />);

    filters = <>
                {!props.product.subscription_product &&
                 <Heading mb={4} fontWeight={600} fontSize={4}>Customize Your Data</Heading> }
                <Box mb={2}>
                  {filterWidgets}
                </Box>
                <Button mb={2} width={1} mx='auto' onClick={props.doSearch}>Search</Button>
                <Button width={1} variant='darkbg.outline' mx='auto' onClick={props.clearSearch}>Clear Filters</Button>
              </>
  }

  return (
    <Sidebar shopConfig={props.shopConfig}>
      {filters}
    </Sidebar>
  )
}

const HorizontalPackageCards = (props) => {
  let packagePanes;
  if (!_.isEmpty(props.packages)) {
    packagePanes = props.packages.map((pkg, i) => {

      return (
        <Flex variant='packageCard' onClick={e => props.openPackage(e)}  mb={4} bg='white' sx={{ borderRadius: '4px', boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)', minWidth: '500px'}} alignItems='center' key={pkg.id}>
          <PackageStrip mr={3} index={i} />

          <Flex flexGrow={1} py={3}>
            <PackageIcon index={i} />
            <Flex flexDirection='column' justifyContent='center' ml={3} py={3} pr={4} >
              <Heading fontSize={4}>{pkg.name}</Heading>
              {pkg.short_description && <Text sx={{maxWidth: ['100%', '300px'] }} mb={3} mt={1} mb={3} fontSize={2} color='gray'>{pkg.short_description}</Text> }
              { props.product.subscription_product ?
                <Price unit='month' price={pkg.price_per_month_formatted} /> :
                <Price unit='row' price={pkg.price_per_row_formatted} /> }
            </Flex>
          </Flex>
        </Flex>
      )
    });


    return (<>{packagePanes}</>);
  } else {
    return null
  }
}

const PackageStrip = (props) => {
  let bgColorLight;
  const index = props.index;

  if (index % 3 == 0) {
    bgColorLight = 'primaryCard1'
  } else if (index % 3 == 1) {
    bgColorLight = 'secondaryCard1'
  } else if (index % 3 == 2) {
    bgColorLight = 'accentCard1'
  }

  return <Box {...props} sx={{ height: '100%', width: '50px' }} bg={bgColorLight} />;
}

const PackageIcon = (props) => {
  let bgColor, bgColorLight, top1, left1, top2, left2;
  const index = props.index;

  if (index % 3 == 0) {
    bgColor = 'primaryCard1'
    bgColorLight = 'primaryCard2'
    top1 = '10px';
    left1 = '30px';

    top2 = '0px';
    left2 = '0px';
  } else if (index % 3 == 1) {
    bgColor = 'secondaryCard1'
    bgColorLight = 'secondaryCard2'
    top1 = '0px';
    left1 = '0px';

    top2 = '48px';
    left2 = '68px';
  } else if (index % 3 == 2) {
    bgColor = 'accentCard1'
    bgColorLight = 'accentCard2'

    top1 = '0px';
    left1 = '30px';

    top2 = '20px';
    left2 = '0px';
  }

  return (
    <Box {...props} m={3} sx={{ height: '122px', width: '142px' }}>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: top1, left: left1,
                   height: '112px', width: '112px', borderRadius: '10px'}} bg={bgColor}>
        </Box>
        <Box sx={{position: 'absolute', height: '74px', width: '74px', top: top2, left: left2, borderRadius: '10px'}} bg={bgColorLight}>
        </Box>
      </Box>
    </Box>
  );
}

const VerticalPackageCards = (props) => {
  let packagePanes;
  if (!_.isEmpty(props.packages)) {
    packagePanes = props.packages.map((pkg, i) => {

      return (
        <Flex variant='packageCard' onClick={e => props.openPackage(pkg)} flexDirection='column' justifyContent='space-between'
        alignItems='center' mb={4} mx={3} p={3} bg='white'
         sx={{ textAlign: 'center', borderRadius: '4px',
          boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)', width: '300px' }} key={pkg.id}>

          <PackageIcon index={i} />

          <Flex flexGrow={1} flexDirection='column' justifyContent='space-between' alignItems='center' p={3} >
            <Heading mb={2} fontSize={4}>{pkg.name}</Heading>
            {pkg.short_description && <Text mb={3} fontSize={2} color='gray'>{pkg.short_description}</Text> }
            { props.product.subscription_product ?
              <Price unit='month' price={pkg.price_per_month_formatted} /> :
              <Price unit='row' price={pkg.price_per_row_formatted} /> }
          </Flex>
        </Flex>
      )
    });


    return (<>{packagePanes}</>);
  } else {
    return null
  }
}

function SplashPage(props) {
  if (props.product) {
    let packagePanes;

    if (props.product.packages.length > 1) {
      packagePanes = <VerticalPackageCards  product={props.product} openPackage={props.openPackage}  packages={props.product.packages} />;
    } else {
      packagePanes = <HorizontalPackageCards  product={props.product} openPackage={props.openPackage}  packages={props.product.packages} />;
    }

    let aboutDatasetPane;
    if (!_.isEmpty(props.product.marketing_materials) || props.product.description) {
      let description;
      let materials;

      if (!_.isEmpty(props.product.marketing_materials)) {
        materials = props.product.marketing_materials.map((m, i) => {
          return ( <Flex mb={2} sx = {{ height: '30px' }} alignItems='center' color='primary' key={i}>
                     <DocumentIcon size={24} />
                     <Link ml={2} fontSize={3} href={m.url}>{m.name}</Link>
                   </Flex> );
        })
      }

      if (props.product.description) {
        const mb = (materials) ? 3 : 0
        description = <Box mb={mb} sx={{lineHeight: '22px', whiteSpace: 'pre-line'}} fontSize={2}>
                        {props.product.description}
                      </Box>
      }

      aboutDatasetPane = (
        <Flex mt={3} flexDirection='column' justifyContent='center' py={4} px={5} bg='white' sx={{ borderRadius: '4px', boxShadow: '0px 16px 25px -12px rgba(15, 89, 109, 0.15)', width: '500px' }}>
          <Heading mx='auto' mb={3} fontSize={4}>About this dataset</Heading>
          {description}
          {materials}
        </Flex>
      );
    }

    return <Flex flexDirection='column' justifyContent='center' alignItems='center' className="mainContent">
             <Heading mx='auto' mb={5} fontSize={5}>{props.product.name}</Heading>

             <Flex flexWrap='wrap' justifyContent='center'>
               {packagePanes}
             </Flex>

             {aboutDatasetPane}
           </Flex>
  } else {
    return <Flex flexDirection='column' justifyContent='center'>
             <Box sx={{height: '150px'}} />
             <Loading />
           </Flex>
  }
}

function DataProductScreen(props) {
  let history = useHistory();
  const [appliedFilters, setAppliedFilters] = useState({})
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState({})
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [searchError, setSearchError] = useState(false);

  let defaultModalPane = 'draft_cart';
  console.log(props.userSession)
  if (props.shopConfig.force_contact_sales &&
      !(props.userSession && props.userSession.role == 'shop_admin')) {
    defaultModalPane = 'contact_sales';
  }

  const [emptySearchFacets, setEmptySearchFacets] = useState({});
  const [emptySearchResult, setEmptySearchResult] = useState({});
  const [modalPane, setModalPane] = useState(defaultModalPane);
  const [activePackageId, setActivePackageId] = useState(null);
  const [dataProduct, setDataProduct] = useState(null);
  const [isError, setIsError] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(null);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [facets, setFacets] = useState({});

  let { dataProductId } =  useParams();

  function toggleModal() {
    setModalIsOpen(!isModalOpen);
  }

  function clearSearch() {
    setAppliedFilters({});
    setFacets(emptySearchFacets);
    setShowSearchResults(false);
    setSearchResult(emptySearchResult);
  }

  function loadDataProduct() {
    console.log('load')
    setIsSearching(true);
    axios.get(`/api/shop/data_products/${dataProductId}?home_screen_id=${props.homeScreen.id}`)
      .then(res => {
        setDataProduct(res.data);
        reallyDoSearch({}, false, function(results) {
          setEmptySearchFacets(results.facets);
          setEmptySearchResult(results);
          setFacets(results.facets);
          setIsLoaded(true);
        });
      })
      .catch(error => {
        if (error.response.status == 401) { // unauthorized
          setIsUnauthorized(true);
        } else {
        console.log(error);
          setIsError(true);
        }
      });
  }

  function reallyDoSearch(filters, navigateAfter, callback) {
    setIsSearching(true);
    axios.post(`/api/shop/data_products/${dataProductId}/searches`,
               { filters: filters })
      .then(response => {
        setIsSearching(false);
        let results = response.data;
        setFacets(response.data.facets);
        setSearchResult(results);
        setSearchError(false);
        if (navigateAfter) {
          setShowSearchResults(true);
        }

        if (callback) {
          callback(response.data);
        }
      })
      .catch(error => {
        setIsSearching(false);
        setSearchResult({})
        setSearchError(true)
      })
  }

  function doSearch() {
    console.log("dosearch called");
    console.log(appliedFilters);

    let searchPath = dataProductSearchPath(dataProductId, appliedFilters);
    history.push(searchPath)

    reallyDoSearch(appliedFilters, true);
  }

  useEffect(() => {
    loadDataProduct();
  }, [])

  useEffect(() => {
    console.log('hash effect');
    if (location.search !== "") {
      let searchParams = new URLSearchParams(location.search);

      let search = {}
      for (const [key, value] of searchParams) {
        search[key] = value.split(',');
      };
      console.log("setting search from URL")
      console.log(search)
      setAppliedFilters(search);
      reallyDoSearch(search, true);
    } else {
      setAppliedFilters({});
    }
  }, [dataProduct])

  useEffect(() => {
    if (!_.isEmpty(appliedFilters)) {
      reallyDoSearch(appliedFilters, true);
    }
  }, [appliedFilters])

  const openPackage = (pkg) => {
    console.log('setting pkg from button');
    setActivePackageId(pkg.id);
    setShowSearchResults(true);
  }

  let mainPageContent;

  if (isUnauthorized) {
    mainPageContent = <Text>This product does not exist or you are not authorized to view it.</Text>;
  } else if (isError) {
    mainPageContent = <Text>We're sorry but something went wrong.</Text>;
  } else if (!isLoaded) {
    mainPageContent = <Box><Box sx={{height: '150px'}} /><Loading /></Box>;
  } else if (searchError) {
    mainPageContent = <Text>We're sorry but something went wrong with your search.</Text>;
  } else if (isSearching) {
    mainPageContent = <Box><Box sx={{height: '150px'}} /><Loading p={4}/></Box>;
  } else if (!showSearchResults) {
    mainPageContent = <SplashPage openPackage={openPackage} product={dataProduct} />
  }  else {
    mainPageContent = <SearchResults setActivePackageId={setActivePackageId} activePackageId={activePackageId} userSession={props.userSession} toggleModal={toggleModal} product={dataProduct} searchResult={searchResult} />
  }

  let modalContent;

  if (modalPane == "draft_cart") {
    modalContent = <DraftCart
                     appliedFilters={appliedFilters}
                     userSession={props.userSession}
                     openContactSales={e => setModalPane("contact_sales")}
                     setCart={props.setCart}
                     dataProduct={dataProduct}
                     searchResult={searchResult}
                     toggleModal={toggleModal} />
  } else {
    modalContent = <ContactSalesScreen openDraftCart={e => setModalPane("draft_cart")}
                                       appliedFilters={appliedFilters}
                                       shopConfig={props.shopConfig}
                                       dataProduct={dataProduct}
                                       toggleModal={toggleModal} />
  }

  return (
    <Flex minWidth='1400px' minHeight={'100vh'}>
      <ModalProvider backgroundComponent={SpecialModalBackground}>
          {isLoaded && <FilterSidebar
                         product={dataProduct}
                         shopConfig={props.shopConfig}
                         history={history}
                         isSearching={isSearching}
                         setAppliedFilters={setAppliedFilters}
                         appliedFilters={appliedFilters}
                         doSearch={doSearch}
                         facets={facets}
                         clearSearch={clearSearch}
                         availableFilters={dataProduct.filters} />}
        {!isLoaded && <Sidebar shopConfig={props.shopConfig} />}

        <Box
          bg='#FAFAFA'
          width={3/4} >
          <Header userSession={props.userSession} dataProduct={dataProduct} />
          <FlashAlert mx='auto' width='50%' location={props.location} />
          <Flex justifyContent='center'>
            {mainPageContent}
          </Flex>
        </Box>

        <StyledModal isOpen={isModalOpen}
                     onEscapeKeydown={toggleModal}>
          {modalContent}
        </StyledModal>
      </ModalProvider>
    </Flex>
  );
}

export default DataProductScreen;
