import axios from '../axios_config.jsx';

export const updateBuyer = (attrs) => {
  return axios.put('/api/shop/buyer', { buyer: attrs });
}

export const getBuyerApiKey = (attrs) => {
  return axios.get('/api/shop/buyer/api_key');
}

export const updateBuyerPaymentMethod = (paymentMethodId) => {
  return axios.post('/api/shop/buyer/update_stripe_payment_method', { payment_method_id: paymentMethodId });
}

