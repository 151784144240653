import React from 'react';

import { Flex, Link } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import {NavLink, NavButton} from '../nav_elements.jsx'

const Header = (props) => {
  const buttonVariant = props.variant || 'outline';
  return <Flex
           sx={{minHeight: '36px'}}
           mb={5}
           mx={5}
           mt={4}
           justifyContent={'flex-end'}
           alignItems={'center'}
           {...props} >
           {props.dataProduct && props.dataProduct.data_dictionary_url && <NavLink variant={buttonVariant} target="_blank" href={props.dataProduct.data_dictionary_url}>Data Dictionary</NavLink>}
           { props.userSession && props.userSession.role == 'shopper' &&
             <NavButton variant={buttonVariant} to="/account/downloads" as={RouterLink}>Downloads</NavButton>
           }
           { props.userSession === null && <NavButton variant={buttonVariant} to="/users/log_in" as={RouterLink}>Log in</NavButton> }
           { props.dataProduct && props.userSession && props.userSession.role == 'shop_admin' && <NavButton variant={buttonVariant} as={Link} href={`/admin/data_products/${props.dataProduct.id}`}>Manage shop</NavButton> }
           { !props.dataProduct && props.userSession && props.userSession.role == 'shop_admin' && <NavButton variant={buttonVariant} as={Link} href={'/admin/home_screen/edit'}>Manage shop</NavButton> }
         </Flex>;
}

export default Header;
